import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';

import { LinearProgress } from '@mui/material';

import { AccountOwnerOnboardingProps, PipedriveOwnerInfo } from '../../../../services/Onboarding/types';
import { ownerAlreadyExists } from '../../utils';
import { useOnboardingHandover } from '../../../../hooks/useOnboarding/useOnboardingHandover';
import { sortAllOwnersList } from '../../../../context/OnboardingContext/utils';

import FormButton from '../../../FormButton';
import TextField from '../../../TextField';
import Grid from './Grid';
import ModalAddProperty from './Modal/ModalAddProperty';

import {
  Section,
  SearchBar,
  SearchContainer,
  ButtonContainer,
  Title,
} from './styles';

const SearchOwners = () => {
  const [isSearching, setIsSearching] = useState<boolean>(false); 

  const {
    listOwner,
    loadingOwner,
    setListOwnerFiltered,
    owner,
    setOwner,
    infosPipeDrive,
    setInfosPipeDrive,
  } = useOnboardingHandover();

  function searchOwner(query: string) {
    const searchQuery = `${query}`.toLowerCase().trim();

    const filtered = listOwner.filter((item) => {
      const fullName = (`${item?.name}`.toLowerCase().trim());
      const cpf = `${item?.cpf}`.toLowerCase().trim();
      const cnpj = `${item?.cnpj}`.toLowerCase().trim();
      const codes = item?.property_codes || [];

      return [fullName, cpf, cnpj].some((field) => field.includes(searchQuery)) || codes.some((code) => `${code}`.toLowerCase().trim().includes(searchQuery));
    });
    
    setInfosPipeDrive({} as PipedriveOwnerInfo);
    return filtered;
  }

  function searchOwnerByName(name: string) {
    return listOwner.filter((item) => {
      const fullName = (`${item?.name}`.toLowerCase().trim());
      const typedName = `${name}`.toLowerCase().trim() || '';
      return fullName.includes(typedName);
    });
  }

  const formik = useFormik({
    initialValues: {
      searchTerm: '',
    },
    onSubmit: (values) => {
      setIsSearching(true);
      const resultFilter = searchOwner(values.searchTerm);
      const sortedList = sortAllOwnersList(
        resultFilter,
        'name',
        'asc',
      );

      setListOwnerFiltered(sortedList);
    },
  });

  useEffect(() => {
    function search() {
      const result = searchOwnerByName(`${infosPipeDrive?.owner_infos?.first_name} ${infosPipeDrive?.owner_infos?.last_name}`);

      if (result.length === 1) {
        const fullName = `${result[0]?.name}`;
        formik.setFieldValue('searchTerm', fullName);
        setOwner({
          ...owner,
          ...result[0],
          user_id: result[0].user_id,
          owner_id: result[0].owner_id,
          name: fullName,
        });
      }
      setListOwnerFiltered(result);
    }
    
    if (infosPipeDrive?.owner_infos?.owner_person_id && listOwner.length > 0) {
      const ownerExists = ownerAlreadyExists(`${infosPipeDrive?.owner_infos?.owner_person_id}`, listOwner);
      if (ownerExists) {
        search();
      } else {
        setListOwnerFiltered([]);
        setOwner({} as AccountOwnerOnboardingProps);
      }
    } 
  }, [infosPipeDrive, listOwner]);

  return (
    <>
      <Section>
        <Title>Pesquisar Proprietário</Title>

        <SearchContainer onSubmit={formik.handleSubmit}>
          <SearchBar style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <TextField
              type="text"
              id="searchTerm"
              placeholder="Pesquisar por nome, código do imóvel, CPF ou CNPJ"
              formik={formik}
              value={formik.values.searchTerm}
              disabled={loadingOwner}
            />

            {loadingOwner && (
              <LinearProgress
                color="primary"
                style={{
                  position: 'absolute',
                  width: '100%',
                  bottom: -2,
                  left: 0,
                  height: 2,
                }}
              />
            )}
          </SearchBar>
          <ButtonContainer>
            <FormButton type="submit" disable={loadingOwner}>
              Buscar
            </FormButton>
          </ButtonContainer>
        </SearchContainer>

        <Grid loading={isSearching} setLoading={setIsSearching} />
        <ModalAddProperty />
      </Section>
    </>
  );
};

export default SearchOwners;
