import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import { UseFormik } from '../../utils/Formik/types';

import HelperText from '../HelperText';
import { Type } from '../HelperText/types';

import { Number } from '../../utils/InputMask';

import InfoIcon from '../../pages/Partners/pages/Indicate/Form/Content/lib/icon/InfoIcon';

import {
  StyledFormControl,
  StyledTextField,
  StyledTextArea,
  ErrorIcon,
  Content,
  ContainerLabel,
  ContentLabel,
  StarSymbol,
} from './styles';

export type Mask = 'money' | 'cpf' | 'cnpj'| 'cpfOrCnpj' | 'cep' | 'phone' | 'hour' | 'agency' | 'account' | 'integerNumber' | 'none';

interface ISelectProps {
  label?: string;
  labelDescription?: JSX.Element;
  id: string;
  dataCy?: string,
  value?: any;
  autoComplete?: 'on' | 'off';
  pattern?: string;
  error?: boolean;
  helperText?: string; // To use with error
  placeholder?: string;
  multiline?: boolean;
  rows?: number; // ATTENTION: Use if multiline is true
  required?: boolean;
  disableRequireSymbol?: boolean;
  requireSymbolPosition?: 'left' | 'right',
  type?: React.InputHTMLAttributes<unknown>['type'];
  minRows?: number | string;
  startAdornment?: React.ReactNode,
  endAdornment?: React.ReactNode,
  formik?: UseFormik<any>,
  mask?: Mask,
  className?: string,
  labelClassName?: string,
  disabled?: boolean,
  activeButton?: boolean,
  onClick?: () => void,
  onChange?:
  ((
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formatedValue: string) => void) | undefined;
  onKeyPress?: KeyboardEventHandler<any>;
  textareaAutoHeight?: boolean,
  activeTooltip?: boolean,
  tooltipTitle?: string,
  disableHelperText?: boolean,
}

const TextField: React.FC<ISelectProps> = ({
  label,
  labelDescription,
  value,
  id,
  dataCy = '',
  autoComplete,
  error,
  helperText,
  placeholder,
  multiline,
  rows,
  required,
  disableRequireSymbol = false,
  requireSymbolPosition = 'left',
  type = 'text',
  minRows,
  startAdornment,
  endAdornment,
  formik,
  onClick,
  onChange,
  onKeyPress,
  mask,
  className,
  labelClassName = 'styledTextFieldLabel',
  disabled = false,
  activeButton = false,
  activeTooltip = false,
  tooltipTitle = '',
  disableHelperText = false,
}) => {
  const masks: Record<Mask, (value: string) => string> = {
    money: Number.currency,
    cpf: Number.cpf,
    cnpj: Number.cnpj,
    cpfOrCnpj: Number.cpfOrCnpj,
    cep: Number.cep,
    phone: Number.phone,
    hour: Number.hour,
    agency: Number.agency,
    account: Number.account,
    integerNumber: Number.integerNumber,
    none: (text: string) => text,
  };

  const [stateValue, setStateValue] = useState<any>();
  const [modifyValue, setModifyValue] = useState<string>(value);

  const formatValue = (str: string) => (mask ? masks[mask](str) : str);

  useEffect(() => {
    if (formik) {
      formik.setFieldValue(id, formatValue((formik?.values[id] || '').toString()));
    }
  }, []);

  useEffect(() => {
    setModifyValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const customValue: string = formatValue(e.currentTarget.value);
    setModifyValue(customValue);
    if (onChange) {
      onChange(e, customValue);
      return;
    }

    if (formik) {
      formik.setFieldValue(id, customValue);
      formik.setFieldTouched(
        id, true, false,
      );
      return;
    }

    setStateValue(customValue);
  };

  const isHelperTextError = formik?.touched[id] && formik?.errors[id] !== '' && formik?.errors[id];

  const getValue = () => {
    if (onChange || value) {
      return value;
    }

    return formik ? formik?.values[id] : stateValue;
  };

  const helperTextValue = () => {
    if (!disableHelperText && isHelperTextError) {
      return (
        <>
          <ErrorIcon />
          {formik?.errors[id]}
        </>
      );
    }

    return helperText;
  };

  const helperTextType = isHelperTextError ? Type.error : Type.primary;

  return (
    <>
      <StyledFormControl className="styledTextFieldContainer">
        <ContainerLabel>
          <ContentLabel>
            {!disableRequireSymbol && required && requireSymbolPosition === 'left' && <StarSymbol>*</StarSymbol>}
            <label className={labelClassName} htmlFor={label}>{label}</label>
            {!disableRequireSymbol && required && requireSymbolPosition === 'right' && <StarSymbol>*</StarSymbol>}
            {activeTooltip && <InfoIcon title={tooltipTitle} />}
          </ContentLabel>
          {labelDescription && <div id="label-description">{labelDescription}</div>}
        </ContainerLabel>
        {type !== 'textarea'
          ? (
            <Content>
              <StyledTextField
                activeButton={activeButton}
                className={className}
                autoComplete={autoComplete}
                name={id}
                id={id}
                data-cy={dataCy}
                variant="outlined"
                type={type}
                error={error}
                placeholder={placeholder}
                multiline={multiline}
                rows={rows}
                required={required}
                value={modifyValue}
                onClick={onClick}
                onChange={handleChange}
                onKeyPress={onKeyPress}
                disabled={disabled}
                fullWidth
                InputProps={{
                  startAdornment,
                  endAdornment,
                  autoComplete: autoComplete === 'off' ? 'no-password' : '',
                  // @ts-ignore
                  form: { autoComplete: 'off' },
                }}
              />
              {activeButton && (
              <button type="submit">Salvar</button>
              )}
            </Content>
          )
          : (
            <StyledTextArea
              name={id}
              id={id}
              data-cy={dataCy}
              placeholder={placeholder}
              minRows={minRows}
              required={required}
              value={getValue()}
              onChange={handleChange}
              disabled={disabled}
              error={error || !!formik?.errors?.[id]}
            />
          )}
        { !!helperTextValue() && (
        <HelperText type={helperTextType}>{helperTextValue()}</HelperText>
        )}
      </StyledFormControl>
    </>
  );
};

export default TextField;
