/* eslint-disable func-names */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
import * as Yup from 'yup';
import { SectionsOptions } from '../../../../context/OnboardingContext/types';
import { UseFormik } from '../../../../utils/Formik/types';

import { isValidPhone } from './utils';

export const ownerInfosValidation = Yup.object().shape({
  typePerson: Yup.string(),
  name: Yup.string().required('Campo obrigatório'),
  lastname: Yup.string().required('Campo obrigatório'),
  email: Yup.string().required().email('Campo obrigatório'),
  personID: Yup.string().required('Campo obrigatório'),
  born: Yup.string().required('Campo obrigatório'),
  phone: Yup.string()
  .required('Campo obrigatório')
  .test(
    'is-valid-phone',
    'Formato de telefone inválido. Ex: +55 (00) 00000-0000 ou +55 (00) 0000-0000',
    (value) => isValidPhone(value || '')
  ),
  cpf: Yup.string().when('typePerson', {
    is: 'individual',
    then: Yup.string().required('O CPF é obrigatório caso seja uma pessoa física')
      .test('is-valid-cpf', 'CPF inválido', (value) => !`${value}`.toLowerCase().trim().includes('invalid')),
  }),
  cnpj: Yup.string().when('typePerson', {
    is: 'legal',
    then: Yup.string().required('O CNPJ é obrigatório caso seja uma pessoa jurídica')
    .test('is-valid-cpf', 'CNPJ inválido', (value) => !`${value}`.toLowerCase().trim().includes('invalid')),
  }),

  nationality: Yup.string().required('Campo obrigatório'),
  profession: Yup.string().required('Campo obrigatório'),
  
  marital_status: Yup.string()
    .nullable()
    .oneOf(['Single', 'Married', 'Divorced', 'Widowed'], 'O estado civil selecionado é inválido')
    .required('Campo obrigatório'),
  
  indicationName: Yup.string().when('isPartnerIndication', {
    is: true,
    then: Yup.string().required('Campo obrigatório'),
  }),

  owner_address_postal_code: Yup.string().when('isForeignUser', {
    is: false,
    then: Yup.string()
      .required('Campo obrigatório')
      .test(
        'is-valid-postal-code',
        'Cep inválido. Ex. 88036-600',
        (value) => !value || /^\d{5}-\d{3}$/.test(value)
      ),
    otherwise: Yup.string().required('Campo obrigatório'),
  }),  
  owner_address_street: Yup.string().required('Campo obrigatório'),
  owner_address_number: Yup.string().required('Campo obrigatório'),
  owner_address_neighborhood: Yup.string().required('Campo obrigatório'),
  owner_address_city: Yup.string().required('Campo obrigatório'),
  owner_address_state: Yup.string().required('Campo obrigatório'),
  owner_address_country: Yup.string().required('Campo obrigatório'),
});

export const propertyInfosValidation = Yup.object().shape({
  propertyCode: Yup.string().required('Campo obrigatório'),
  categoryLocationId: Yup.string().required('Campo obrigatório'),
  bedroomsAmount: Yup.number().min(1, 'Informe um valor maior do que zero').required('Campo obrigatório'),
  propertyType: Yup.string().required('Campo obrigatório'),
  comment: Yup.string(),
  condominium: Yup.string().when('inCondominium', {
    is: true,
    then: Yup.string().required('Campo obrigatório'),
  }),

  property_address_postal_code: Yup.string().when('isForeignUser', {
    is: false,
    then: Yup.string()
      .required('Campo obrigatório')
      .test(
        'is-valid-postal-code',
        'Cep inválido. Ex. 88036-600',
        (value) => !value || /^\d{5}-\d{3}$/.test(value)
      ),
    otherwise: Yup.string().required('Campo obrigatório'),
  }),  
  property_address_street: Yup.string().required('Campo obrigatório'),
  property_address_number: Yup.string().required('Campo obrigatório'),
  property_address_neighborhood: Yup.string().required('Campo obrigatório'),
  property_address_city: Yup.string().required('Campo obrigatório'),
  property_address_state: Yup.string().required('Campo obrigatório'),
  property_address_country: Yup.string().required('Campo obrigatório'),
});

export const feesAndComissionsValidation = Yup.object().shape({
  plan: Yup.string().required('Campo obrigatório'),
  totalImplantationFee: Yup.string().required('Campo obrigatório'),
  commissions: Yup.number().required('Campo obrigatório').min(0, 'Valor mínimo dever ser >= 0').max(100, 'Valor não pode ultrapassar 100'),
  hostReservationComissionFee: Yup.number()
    .typeError('O valor deve ser um número')
    .required('O campo é obrigatório')
    .test(
      'comission-host',
      'Taxa de comissão zerada é permitida apenas para Gestão Digital',
      function validate(value) {
        const { parent } = this;
        if (Number(value) <= 0 && parent.plan !== 'Digital_Management') return false;
        return true;
      },
    )
    .max(100, 'Valor não pode ultrapassar 100'),
  paymentMethod: Yup.string().required('Campo obrigatório'),
  host: Yup.string().required('Campo obrigatório'),
  haveBedding: Yup.string().required('Campo obrigatório'),
});

export const finalInfosValidation = Yup.object().shape({
  onboardingName: Yup.string().when('isOwnerContact', {
    is: false,
    then: Yup.string().required('Campo obrigatório'),
  }),
  onboardingPhone: Yup.string().when('isOwnerContact', {
  is: false,
  then: Yup.string()
    .test(
      'is-valid-phone',
      'Formato de telefone inválido. Ex: +55 (00) 00000-0000 ou +55 (00) 0000-0000',
      (value) => isValidPhone(value || '')
    )
    .required('Campo obrigatório'),
  }),
});

export const finalInfosWithoutPasswordValidation = Yup.object().shape({
  onboardingName: Yup.string().when('isOwnerContact', {
    is: false,
    then: Yup.string().required('Campo obrigatório'),
  }),
  onboardingPhone: Yup.string().when('isOwnerContact', {
  is: false,
  then: Yup.string()
    .test(
      'is-valid-phone',
      'Formato de telefone inválido. Ex: +55 (00) 00000-0000 ou +55 (00) 0000-0000',
      (value) => isValidPhone(value || '')
    )
    .required('Campo obrigatório'),
  }),
});

export const propertyValidations = propertyInfosValidation.concat(feesAndComissionsValidation).concat(finalInfosValidation);
export const propertyValidations2 = propertyInfosValidation.concat(feesAndComissionsValidation).concat(finalInfosWithoutPasswordValidation);
export const validations = ownerInfosValidation.concat(propertyValidations);

export const getValidationSchema = (disableOwnerValidations: boolean) => {
  if (disableOwnerValidations) {
    return propertyValidations2;
  }
  return validations;
};

export type ValidationOptions = {
  formik: UseFormik<any>, 
  ownerCountryIsBrazil?: boolean, 
  ownerAlreadyExists?: boolean
};

export const validationsFunctions: Record<SectionsOptions, (options: ValidationOptions) => Promise<any>> = {
  Section_Owner_Infos: async (options: ValidationOptions) => ownerInfosValidation.validate(options.formik.values, { abortEarly: false }).then(() => []).catch(err => err.inner),
  Section_Property_Infos: async (options: ValidationOptions) => propertyInfosValidation.validate(options.formik.values, { abortEarly: false }).then(() => []).catch(err => err.inner),
  Section_Fees_Commissions: async (options: ValidationOptions) => feesAndComissionsValidation.validate(options.formik.values, { abortEarly: false }).then(() => []).catch(err => err.inner),
  Section_Invoice_Details: async (options: ValidationOptions) => {
    const sectionOwnerInfosErrors = await ownerInfosValidation.validate(options.formik.values, { abortEarly: false }).then(() => []).catch(err => err.inner);
    const sectionPropertyInfosErrors = await propertyInfosValidation.validate(options.formik.values, { abortEarly: false }).then(() => []).catch(err => err.inner);

    const mainOwnerErrors = Array.isArray(sectionOwnerInfosErrors) ? sectionOwnerInfosErrors.filter((error: any) =>
      ['name', 'lastname', 'cpf', 'cnpj'].includes(error.path)
    ) : [];

    const ownerAddressErrors = Array.isArray(sectionOwnerInfosErrors) ? sectionOwnerInfosErrors.filter((error: any) =>
      ['owner_address_postal_code', 'owner_address_street', 'owner_address_number',
        'owner_address_neighborhood', 'owner_address_city', 'owner_address_state',
        'owner_address_country'].includes(error.path)
    ) : [];

    const propertyAddressErrors = Array.isArray(sectionPropertyInfosErrors) ? sectionPropertyInfosErrors.filter((error: any) =>
      ['property_address_postal_code', 'property_address_street', 'property_address_number',
        'property_address_neighborhood', 'property_address_city', 'property_address_state',
        'property_address_country'].includes(error.path)
    ) : [];

    return mainOwnerErrors.length + (options?.ownerCountryIsBrazil ? ownerAddressErrors.length : propertyAddressErrors.length);
  },
  Section_Final_Infos: async (options: ValidationOptions) => {
    const validation = options.ownerAlreadyExists ? finalInfosWithoutPasswordValidation : finalInfosValidation;
    return validation.validate(options.formik.values, { abortEarly: false }).then(() => []).catch(err => err.inner);
  }
};

export const getTotalErrors = async (options: ValidationOptions) => {
  const keys = options.ownerAlreadyExists ? ['Section_Property_Infos', 'Section_Fees_Commissions', 'Section_Final_Infos'] : Object.keys(validationsFunctions);

  const errorsArray = await Promise.all(
    keys.map(async (key) => {
      const validationErrors = await validationsFunctions[key as SectionsOptions](options);
      return { [key]: Array.isArray(validationErrors) ? validationErrors.length : validationErrors };
    })
  );

  const defaultErrors: Record<SectionsOptions, number> = keys.reduce((acc, key) => {
    acc[key as SectionsOptions] = 0;
    return acc;
  }, {} as Record<SectionsOptions, number>);

  const errors = errorsArray.reduce<Record<SectionsOptions, number>>((acc, error) => ({ ...acc, ...error }), defaultErrors);
  return errors;
};

export const handleValidation = async (formik: UseFormik<any>) => {
  const errors = await formik.validateForm();

  const invalidFields = Object.keys(formik.values).filter(
    (key) => `${formik.values[key]}`.toLowerCase().trim() === 'invalid'
  );

  if (Object.keys(errors).concat(invalidFields).length > 0) {
    formik.setTouched(
      Object.keys(errors).reduce((acc: any, key: any) => {
        acc[key] = true;
        return acc;
      }, {}),
    );
  }
};
