import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid #C2C8F1;
  margin: 32px 0;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  padding-left: 10px;
`;

export const TotalErrors = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  color: ${({ theme }) => theme.palette.grey._560.hex()};
`;

export const DropIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
`;
