/* eslint-disable no-empty-pattern */
import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import arrowBottom from '../../../../../assets/icons/generals/arrowBottomBlack.svg';
import arrowTop from '../../../../../assets/icons/generals/arrowTopBlack.svg';

import { AccountOwnerOnboardingProps } from '../../../../../services/Onboarding/types';
import { getAccountUserDetailsByUserId } from '../../../../../services/User/request';

import { useOnboardingHandover } from '../../../../../hooks/useOnboarding/useOnboardingHandover';
import { useScreenResize } from '../../../../../hooks/useSceenResize/useScreenResize';
import { translateHeaderLabel } from '../../../../../context/OnboardingContext/utils';

import Tooltip from '../../../../Tooltip';
import Pagination from '../../../../InsertData/InsertChangePropertyHostData/Pagination';

import {
  ContainerArrows,
  Image,
  GridContainer,
  GridContent,
  GridHeaderContainer,
  GridHeaderContent,
  GridHeaderTitle,
  GridBodyContainer,
  GridBodyContent,
  GridBodyRow,
  GridBodyTitle,
  CardContainer,
  CardContentLeft,
  CardContentRight,
  CardInfo,
  IconContainer,
  TooltipContent,
} from './styles';

interface IGrid {
  loading: boolean,
  setLoading: Function,
}

const Grid = ({ loading, setLoading }: IGrid) => {
  const { isMobile } = useScreenResize(900);
  const {
    setOwner,
    setOpenModalAddProperty,
    orderGridBy,
    setOrderGridBy,
    listOwnerFiltered
  } = useOnboardingHandover();

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [loading, listOwnerFiltered]);

  const ITEMS_PER_PAGE = useMemo(() => (isMobile ? 3 : 10), [isMobile]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    data,
    maxPages,
    order,
  } = useMemo(() => {
    if (listOwnerFiltered.length === 0) {
      return {
        data: [],
        maxPages: 0,
        order: {
          startPosition: 0,
          endPosition: 0,
        },
      };
    }

    const start = (currentPage - 1) * ITEMS_PER_PAGE;
    const end = listOwnerFiltered.length > ITEMS_PER_PAGE ? start + ITEMS_PER_PAGE : listOwnerFiltered.length;

    return {
      data: listOwnerFiltered.slice(start, end),
      maxPages: Math.ceil(listOwnerFiltered.length / ITEMS_PER_PAGE),
      order: {
        startPosition: start,
        endPosition: end,
      },
    };
  }, [listOwnerFiltered, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [listOwnerFiltered]);

  const handlePrevPage = () => {
    setCurrentPage((page) => (page - 1 > 0 ? page - 1 : page));
  };

  const handleNextPage = () => {
    setCurrentPage((page) => (page + 1 <= maxPages ? page + 1 : page));
  };

  const handleSortGrid = (label: string) => {
    setOrderGridBy({
      order_by: translateHeaderLabel[label],
      order_option: 'all',
      order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
    });
  };

  interface ITooltipMoreProperties {
    codes: string,
  }

  const MAX_CODES_TO_SHOW = 4;
  const TooltipMoreProperties = ({ codes }: ITooltipMoreProperties) => {
    if (codes === '') return null;

    const totalCodes = `${codes}`.split(',').length;

    return (
      <Tooltip text={codes} zIndex={1}>
        <TooltipContent>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 10.3333H7.33333V6.33325H6V10.3333ZM6.66667 4.99992C6.85556 4.99992 7.014 4.93592 7.142 4.80792C7.27 4.67992 7.33378 4.5217 7.33333 4.33325C7.33333 4.14436 7.26933 3.98592 7.14133 3.85792C7.01333 3.72992 6.85511 3.66614 6.66667 3.66659C6.47778 3.66659 6.31933 3.73059 6.19133 3.85859C6.06333 3.98659 5.99956 4.14481 6 4.33325C6 4.52214 6.064 4.68059 6.192 4.80859C6.32 4.93659 6.47822 5.00036 6.66667 4.99992ZM6.66667 13.6666C5.74444 13.6666 4.87778 13.4915 4.06667 13.1413C3.25556 12.791 2.55 12.3161 1.95 11.7166C1.35 11.1166 0.875111 10.411 0.525333 9.59992C0.175556 8.78881 0.000444444 7.92214 0 6.99992C0 6.0777 0.175111 5.21103 0.525333 4.39992C0.875556 3.58881 1.35044 2.88325 1.95 2.28325C2.55 1.68325 3.25556 1.20836 4.06667 0.858585C4.87778 0.508808 5.74444 0.333696 6.66667 0.333252C7.58889 0.333252 8.45556 0.508363 9.26667 0.858585C10.0778 1.20881 10.7833 1.6837 11.3833 2.28325C11.9833 2.88325 12.4584 3.58881 12.8087 4.39992C13.1589 5.21103 13.3338 6.0777 13.3333 6.99992C13.3333 7.92214 13.1582 8.78881 12.808 9.59992C12.4578 10.411 11.9829 11.1166 11.3833 11.7166C10.7833 12.3166 10.0778 12.7917 9.26667 13.1419C8.45556 13.4921 7.58889 13.667 6.66667 13.6666Z" fill="#4360B7" />
          </svg>
          <p>+<span>{`${totalCodes}`}</span></p>
        </TooltipContent>
      </Tooltip>
    );
  };

  const propertyCodesDisplay = (codes: string[]) => {
    const codesSorted = codes.sort();

    if (codesSorted.length >= MAX_CODES_TO_SHOW) {
      return {
        table: codesSorted.slice(0, MAX_CODES_TO_SHOW).join(', '),
        tooltip: codesSorted.slice(MAX_CODES_TO_SHOW, codesSorted.length).join(', '),
      }
    }

    return {
      table: codesSorted.join(', '),
      tooltip: '',
    };
  };

  const handleGetUserDetails = async (ownerSelected: AccountOwnerOnboardingProps) => {
    const ownerDetails = await getAccountUserDetailsByUserId(ownerSelected.user_id);
    setOwner({
      ...ownerSelected,
      ...ownerDetails,
      user_id: ownerDetails.id,
      owner_id: ownerDetails.owner.id,
      name: `${ownerDetails.first_name} ${ownerDetails.last_name}`,
    });
  };

  async function handleNextPhase(item: AccountOwnerOnboardingProps) {
    await handleGetUserDetails(item);
    setOpenModalAddProperty({
      open: true,
      variant: '1'
    });
  }

  interface IButtonAction {
    item: AccountOwnerOnboardingProps,
  };

  const ButtonAction = ({ item }: IButtonAction) => (
    <Tooltip text="Adicionar Imóvel" zIndex={2}>
      <IconContainer onClick={() => handleNextPhase(item)}>
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 13.9997V10.9997M9 10.9997V7.99973M9 10.9997H6M9 10.9997H12M1 14.7997V9.45173C1 8.91773 1 8.65073 1.065 8.40173C1.123 8.18173 1.217 7.97273 1.345 7.78473C1.49 7.57173 1.691 7.39473 2.093 7.04373L6.894 2.84173C7.64 2.18973 8.013 1.86373 8.432 1.73973C8.802 1.62973 9.197 1.62973 9.567 1.73973C9.987 1.86373 10.361 2.18973 11.107 2.84373L15.907 7.04373C16.31 7.39573 16.51 7.57173 16.655 7.78373C16.7828 7.97228 16.8775 8.18128 16.935 8.40173C17 8.64973 17 8.91773 17 9.45173V14.8037C17 15.9217 17 16.4807 16.782 16.9087C16.5901 17.2847 16.2842 17.5902 15.908 17.7817C15.48 17.9997 14.922 17.9997 13.804 17.9997H4.197C3.079 17.9997 2.519 17.9997 2.092 17.7817C1.71584 17.5902 1.40991 17.2847 1.218 16.9087C1 16.4797 1 15.9197 1 14.7997Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </IconContainer>
    </Tooltip>
  );

  const ContentDesktop = () => {
    const GRIDHEADERS = ['Proprietário', 'PERSON ID', 'Imóveis Ativos', 'Ação'];

    return (
      <GridContent>
        {data.length !== 0 && (
          <>
            <GridHeaderContainer>
              {GRIDHEADERS.map((header) => (
                <GridHeaderContent key={header}>
                  <GridHeaderTitle hide={header} data-cy={`title-${header}`}>{header}</GridHeaderTitle>

                  {!['Ação'].includes(header) && (
                    <Tooltip text={`Ordenar ${header}`}>
                      <ContainerArrows onClick={() => handleSortGrid(header)}>
                        <Image src={arrowTop} alt="Seta para cima" />
                        <Image src={arrowBottom} alt="Seta para baixo" />
                      </ContainerArrows>
                    </Tooltip>
                  )}
                </GridHeaderContent>
              ))}
            </GridHeaderContainer>

            <GridBodyContainer>
              {data.map((item: AccountOwnerOnboardingProps) => (
                <GridBodyRow key={item.owner_id} loading={loading}>
                  <GridBodyContent>
                    <GridBodyTitle className="title-body" data-cy={`title-name}`}>{`${item?.name || ''}`}</GridBodyTitle>
                  </GridBodyContent>
                  <GridBodyContent>
                    <GridBodyTitle className="title-body" data-cy={`title-person-id}`}>{item?.pipedrive_person_id || '--'}</GridBodyTitle>
                  </GridBodyContent>

                  <GridBodyContent>
                    <GridBodyTitle className="title-body" data-cy={`title-code}`}>
                      <div className="properties-counter">
                        <p>{item?.property_codes?.length !== 0 ? propertyCodesDisplay(item?.property_codes || []).table : '--'}</p>
                        <div><TooltipMoreProperties codes={propertyCodesDisplay(item?.property_codes || []).tooltip} /></div>
                      </div>
                    </GridBodyTitle>
                  </GridBodyContent>
                  <GridBodyContent>
                    <GridBodyTitle className="title-body" data-cy={`title-action}`}><ButtonAction item={item} /></GridBodyTitle>
                  </GridBodyContent>
                </GridBodyRow>
              ))}
            </GridBodyContainer>
          </>
        )}
      </GridContent>
    );
  };

  interface IContentMobile {
    item: AccountOwnerOnboardingProps;
  }

  const ContentMobile = ({ item }: IContentMobile) => {
    const CARDHEADERS = ['Proprietário', 'PERSON ID', 'Imóveis Ativos', 'Ação'];

    const INDEXS = CARDHEADERS.map((_, index) => index);

    const CARDBODY = [
      `${item?.name || ''}`,
      item?.pipedrive_person_id || '--',
      item?.property_codes?.length !== 0 ? propertyCodesDisplay(item?.property_codes || []).table : '--',
      // '--'
    ];

    return (
      <CardContainer>
        <CardContentLeft>
          {INDEXS.slice(0, 3).map((idx) => (
            <CardInfo key={idx}>
              <GridHeaderTitle hide={CARDHEADERS[idx]} data-cy={`title-${CARDHEADERS[idx]}`}>{CARDHEADERS[idx]}</GridHeaderTitle>
              <GridBodyTitle className="title-body" data-cy={`title-${CARDBODY[idx]}`}>{CARDBODY[idx]}</GridBodyTitle>
            </CardInfo>
          ))}
        </CardContentLeft>
        <CardContentRight>
          {INDEXS.slice(3, INDEXS.length).map((idx) => (
            <CardInfo key={idx}>
              <GridHeaderTitle hide={CARDHEADERS[idx]} data-cy={`title-${CARDHEADERS[idx]}`}>{CARDHEADERS[idx]}</GridHeaderTitle>
              <GridBodyTitle className="title-body" data-cy={`title-${CARDBODY[idx]}`}>{CARDBODY[idx]}</GridBodyTitle>
            </CardInfo>
          ))}
        </CardContentRight>
      </CardContainer>
    );
  };

  return (
    <GridContainer>
      {isMobile ? data.map((item) => (
        <ContentMobile
          key={item.owner_id}
          item={item}
        />
      )) : <ContentDesktop />}

      {maxPages > 1 && (
        <Pagination
          page={currentPage}
          totalPages={maxPages}
          onClickPrevPage={handlePrevPage}
          onClickNextPage={handleNextPage}
          disableButtonPrev={currentPage === 1}
          disableButtonNext={currentPage === maxPages}
        />
      )}
    </GridContainer>
  );
};

export default Grid;
