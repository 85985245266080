import { ReactNode, useEffect } from 'react';

import { PagesOptions } from '../../context/OnboardingContext/types';

import { compareList } from '../../utils/Sorting';

import { getAccountSimpleHosts } from '../../services/AccountSimpleHosts/request';
import { getAccountOwnerOnboarding } from '../../services/Onboarding/request';
import { getPartners } from '../../services/Partner';
import { getCategoryLocationForSelect } from '../../services/InsertData/request';

import { useOnboardingHandover } from '../../hooks/useOnboarding/useOnboardingHandover';
import { usePropertyCategoryLocation } from '../../hooks/usePropertyCategoryLocation/usePropertyCategoryLocation';

import PageInitial from './PageInitial';
import PageForms from './PageForms/Forms';
import PageFinal from './PageFinal';

import {
  Wrapper,
  Container,
  Header,
  Title,
} from './styles';

const OnboardingPage = () => {
  const {
    setListOwner,
    setListHost,
    setListPartner,
    setLoadingOwner,
    pageVisible,
  } = useOnboardingHandover();

  const { setCategoriesLocations } = usePropertyCategoryLocation();

  function getAllOwners() {
    setLoadingOwner(true);

    getAccountOwnerOnboarding()
      .then((result) => {
        setListOwner(result);
        setLoadingOwner(false);
      }).catch(() => {
        setListOwner([]);
        setLoadingOwner(false);
      }
      );
  }

  function getAllHosts() {
    getAccountSimpleHosts()
      .then((result) => setListHost(result))
      .catch(() => setListHost([]));
  }

  function getAllPartners() {
    getPartners()
      .then((result) => {
        const sorted = result
          .filter((partner) => partner.user.is_active)
          .sort((a, b) => compareList(a.user.first_name, b.user.first_name));

        setListPartner(sorted);
      })
      .catch(() => setListPartner([]));
  }

  function getAllCategories() {
    getCategoryLocationForSelect()
      .then((result) => setCategoriesLocations(result))
      .catch(() => setCategoriesLocations([]));
  }

  useEffect(() => {
    getAllOwners();
    getAllHosts();
    getAllPartners();
    getAllCategories();
  }, []);

  const page: Record<PagesOptions, ReactNode> = {
    Page_Initial: <PageInitial />,
    Page_Forms: <PageForms />,
    Page_Final: <PageFinal />,
  };

  return (
    <Wrapper>
      <Header>
        <Title>Handover Proprietário</Title>
      </Header>
        <Container pageVisible={pageVisible}>
          {page[pageVisible]}
        </Container>
    </Wrapper>
  );
}

export default OnboardingPage;
