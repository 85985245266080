/* eslint-disable no-nested-ternary */
import moment from 'moment';

import { PlanOptions } from "../../../../context/OnboardingContext/types";
import { normalizeState } from "../../../../hooks/useIBGE/utils";
import { AccountOwnerOnboardingProps, PipedriveOwnerInfo } from "../../../../services/Onboarding/types";
import { isBrazil, numberToCurrency } from "../../../../utils/Formatter";
import { translateMaritalStatus, translateTypeProperty } from "../../../../utils/Translator";
import { normalizeCountry } from '../../../../services/Address/countries';
import { IAccountSimpleHostsResponse } from '../../../../services/AccountSimpleHosts/types';
import { RequestPartner } from '../../../../services/Partner/types';
import { GetCategoryLocationData } from '../../../../services/InsertData/types';
import { FormikProps } from './types';

export const numberRegex = /.*[0-9].*/;
export const lowercaseRegex = /.*[a-z].*/;
export const uppercaseRegex = /.*[A-Z].*/;
export const specialCharRegex = /.*[!@#$%^&*()_\-+={}[\]|:;"'<>,.?/].*/;
export const phoneRegExp = /^\+[1-9][0-9]{0,2} \(\d{2}\) (\d{4,5})-\d{4}$/;

export const commonPasswords = [
  'user',
  'email',
  'teste',
  'senha',
  'admin',
  '123456',
  'password',
  'username',
  'teste1234',
  'teste123!',
  'Teste123!',
];

export const generateRandomPassword = () => {
  const minLength = 8;
  const maxLength = 12;
  const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

  const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
  const numberChars = "0123456789";
  const specialChars = "!@#$%^&*+<>?;~";

  const allChars = upperCaseChars + lowerCaseChars + numberChars + specialChars;

  let password = "";
  password += upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)];
  password += lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)];
  password += numberChars[Math.floor(Math.random() * numberChars.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  for (let i = password.length; i < length; i+=1) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  return password.split("").sort(() => Math.random() - 0.5).join("");
};

export const getRandomPassword = (ownerExists?: boolean) => {
  const pass = generateRandomPassword();
  return {
    password: !ownerExists ? pass : undefined,
    passwordConfirm: !ownerExists ? pass : undefined,
  }
};

export const initialValuesForms: FormikProps = {
  // INFORMACOES DO PROPRIETARIO
  isForeignUser: false,
  personID: '',
  typePerson: 'individual',
  cpf: '',
  cnpj: '',
  name: '',
  lastname: '',
  born: '',
  email: '',
  phone: '',
  profession: '',
  nationality: '',
  marital_status: null,
  isPartnerIndication: false,
  indicationName: '',
  owner_address_postal_code: '',
  owner_address_street: '',
  owner_address_number: '',
  owner_address_neighborhood: '',
  owner_address_city: '',
  owner_address_state: '',
  owner_address_country: 'Brasil',
  owner_address_complement: '',

  // INFORMACOES DO IMOVEL
  propertyCode: '',
  inCondominium: false,
  condominium: '',
  property_address_city: '',
  property_address_neighborhood: '',
  property_address_number: '',
  property_address_state: '',
  property_address_street: '',
  property_address_postal_code: '',
  property_address_complement: '',
  property_address_country: 'Brasil',
  categoryLocationId: 'ADEFINIR',
  bedroomsAmount: 0,
  propertyAreaSizeM2: 0,
  propertyType: '',
  comment: '',

  // TAXAS E COMISSOES
  qtdInstallments: 0,
  valueBalanceDiscountRate: 100,
  host: undefined,
  haveBedding: false,
  commissions: undefined,
  description: '',
  paymentMethod: '',
  totalImplantationFee: '',
  plan: undefined as any,
  hostReservationComissionFee: 8,

  // INFORMACOES FINAIS
  isOwnerContact: false,
  onboardingName: '',
  onboardingPhone: '',
  // ...getRandomPassword(false),
  password: '',
  passwordConfirm: ''
}

export const isValidPhone = (value: string): boolean => {
  if (!value) return false;

  if (value.startsWith('55') || value.startsWith('+55')) {
    // Extrai código de área e restante do número
    const areaCode = value.slice(2, 4);
    const rest = value.slice(4);

    let formattedValue = '';
    if (rest.length === 8) {
      // Telefone fixo: 4 dígitos para cada parte
      formattedValue = `+55 (${areaCode}) ${rest.slice(0, 4)}-${rest.slice(4)}`;
    } else if (rest.length === 9) {
      // Celular: 5 dígitos na primeira parte e 4 na segunta parte
      formattedValue = `+55 (${areaCode}) ${rest.slice(0, 5)}-${rest.slice(5)}`;
    } else {
      return false; // Número inválido
    }

    // Valida número formatado com regex
    return phoneRegExp.test(formattedValue);
  }

  return true; // Desativa validação para números que Não iniciam com +55 ou 55
};

export function formatPhoneToShow(phone: string, ownerCountryIsBrazil: boolean, isOwnerContact?: boolean, ) {
  if (isOwnerContact) return phone;
  if (['null', 'undefined'].includes(`${phone}`)) return '';

  const phoneNumber = `${phone}`.replace(/\D/g, '');

  if (ownerCountryIsBrazil && !phoneNumber.startsWith('+55') && !phoneNumber.startsWith('55')) {
    return `+55 ${phoneNumber}`;
  }

  return phoneNumber;
}

export function formatPhoneToSubmit(phone: string) {
  if (['null', 'undefined'].includes(`${phone}`)) return '';

  const phoneNumber = `${phone}`.replace(/\D/g, '');

  if (!phoneNumber.startsWith('+')) {
    return `+${phoneNumber}`;
  }

  return phoneNumber;
}

export function clearString(str: string) {
  return str.replace(/[,*\-().]/g, '');
}

export const getOwnerInfosDetails = (infosPipeDrive: PipedriveOwnerInfo, owner: AccountOwnerOnboardingProps, stateMappings: Record<string, string[]>) => ({
  isForeignUser: !isBrazil(infosPipeDrive?.owner_infos?.owner_address?.owner_country || owner?.main_address?.country || 'Brasil'),
  personID: `${infosPipeDrive?.owner_infos?.owner_person_id || owner?.pipedrive_person_id || ''}`,
  
  typePerson: infosPipeDrive?.owner_infos?.is_individual 
    ? (infosPipeDrive?.owner_infos?.is_individual ? 'individual' : 'legal') 
    : (owner?.is_individual ? 'individual' : 'legal'),
  
  cpf: infosPipeDrive?.owner_infos?.cpf || owner?.cpf || '',
  cnpj: infosPipeDrive?.owner_infos?.cnpj || owner?.cnpj || '',
  name: infosPipeDrive?.owner_infos?.first_name || owner?.first_name || '',
  lastname: infosPipeDrive?.owner_infos?.last_name || owner?.last_name || '',
  
  born: infosPipeDrive?.owner_infos?.birth_date
    ? (infosPipeDrive?.owner_infos?.birth_date ? moment(infosPipeDrive?.owner_infos?.birth_date).format('MM-DD-YYYY') : '')
    : (owner?.birth_date ? moment(owner?.birth_date).format('MM-DD-YYYY') : ''),
  
  email: infosPipeDrive?.owner_infos?.email || owner?.email || '',

  phone: infosPipeDrive?.owner_infos?.phone_number
  ? formatPhoneToShow(
      infosPipeDrive?.owner_infos?.phone_number || '', 
      isBrazil(infosPipeDrive?.owner_infos?.owner_address?.owner_country || 'Brasil'),
      false,
    ) 
  : formatPhoneToShow(
    owner?.phone_number1 || '', 
    isBrazil(owner?.main_address?.country || 'Brasil'),
    false,
  ),

  profession: infosPipeDrive?.owner_infos?.owner_profession || '',
  nationality: infosPipeDrive?.owner_infos?.nationality || '',
  marital_status: translateMaritalStatus[`${infosPipeDrive?.owner_infos?.marital_status || ''}`.toLowerCase()],
  isPartnerIndication: infosPipeDrive?.is_partner_indication || false,
  indicationName: infosPipeDrive?.partner_name || '',

  owner_address_postal_code: infosPipeDrive?.owner_infos?.owner_address?.owner_postal_code || owner?.main_address?.postal_code || '',
  owner_address_street: infosPipeDrive?.owner_infos?.owner_address?.owner_street || owner?.main_address?.street || '',
  owner_address_number: infosPipeDrive?.owner_infos?.owner_address?.owner_number || owner?.main_address?.number || '',
  owner_address_neighborhood: infosPipeDrive?.owner_infos?.owner_address?.owner_neighborhood || owner?.main_address?.neighborhood || '',
  owner_address_city: infosPipeDrive?.owner_infos?.owner_address?.owner_city || owner?.main_address?.city || '',
  owner_address_state: normalizeState(stateMappings, infosPipeDrive?.owner_infos?.owner_address?.owner_state || owner?.main_address?.state || ''),
  owner_address_country: normalizeCountry(infosPipeDrive?.owner_infos?.owner_address?.owner_country || owner?.main_address?.country || ''),
  owner_address_complement: infosPipeDrive?.owner_infos?.owner_address?.owner_complement || owner?.main_address?.complement || '', 
});

export const getPropertyInfosDetails = (infosPipeDrive: PipedriveOwnerInfo, stateMappings: Record<string, string[]>) => ({
  propertyCode: infosPipeDrive?.property_infos?.property_code || '',

  inCondominium: !["", 'null', 'undefined'].includes(`${infosPipeDrive?.property_infos?.property_address?.condominium_name}`),
  condominium: infosPipeDrive?.property_infos?.property_address?.condominium_name || '',

  property_address_city: infosPipeDrive?.property_infos?.property_address?.property_city || '',
  property_address_neighborhood: infosPipeDrive?.property_infos?.property_address?.property_neighborhood || '',
  property_address_number: infosPipeDrive?.property_infos?.property_address?.property_number || '',
  property_address_state: normalizeState(stateMappings, infosPipeDrive?.property_infos?.property_address?.property_state || ''),
  property_address_street: infosPipeDrive?.property_infos?.property_address?.property_street || '',
  property_address_postal_code: infosPipeDrive?.property_infos?.property_address?.property_postal_code || '',
  property_address_complement: infosPipeDrive?.property_infos?.property_address?.property_complement || '',
  property_address_country: normalizeCountry(infosPipeDrive?.property_infos?.property_address?.property_country || 'Brasil'),

  categoryLocationId: infosPipeDrive?.property_infos?.property_category || 'ADEFINIR',
  bedroomsAmount: infosPipeDrive?.property_infos?.bedroom_quantity || 0,
  propertyAreaSizeM2: infosPipeDrive?.property_infos?.property_size || 0,
  propertyType: translateTypeProperty(infosPipeDrive?.property_infos?.property_type || ''),

  comment: infosPipeDrive?.property_infos?.property_comment || '',
});

export const getFeesAndComissionsDetails = (infosPipeDrive: PipedriveOwnerInfo) => ({
  qtdInstallments: 0,
  valueBalanceDiscountRate: 100,
  host: infosPipeDrive?.implantation_fees?.host_id || undefined,
  haveBedding: infosPipeDrive?.implantation_fees?.have_bedding || false,
  commissions: infosPipeDrive?.seazone_commission ? Number(infosPipeDrive?.seazone_commission) : undefined,
  description: infosPipeDrive?.implantation_fees?.description || '',
  paymentMethod: infosPipeDrive?.implantation_fees?.payment_method || '',
  totalImplantationFee: infosPipeDrive?.implantation_fees?.implantation_fee_value ? numberToCurrency(infosPipeDrive?.implantation_fees?.implantation_fee_value) : '',
  plan: infosPipeDrive?.implantation_fees?.plan_choosed as PlanOptions,
  hostReservationComissionFee: infosPipeDrive?.host_commission ? Number(infosPipeDrive?.host_commission) : 8,
});

export const getFinalInfosDetails = (infosPipeDrive: PipedriveOwnerInfo, ownerExists: boolean) => ({
  isOwnerContact: typeof infosPipeDrive?.property_infos?.is_owner_contact === 'boolean' 
  ? infosPipeDrive.property_infos.is_owner_contact 
  : false,
  onboardingName: infosPipeDrive?.property_infos?.onboarding_name || '',
  onboardingPhone: formatPhoneToShow(
    infosPipeDrive?.property_infos?.onboarding_phone || '',
    isBrazil(infosPipeDrive?.owner_infos?.owner_address?.owner_country || 'Brasil'),
    infosPipeDrive?.property_infos?.is_owner_contact || false
  ),
  // ...getRandomPassword(ownerExists),
  password: '',
  passwordConfirm: '',
});

export const getHostSelected = (listHost: Array<IAccountSimpleHostsResponse>, infosPipeDrive: PipedriveOwnerInfo) => {
  let hostName = '';
  let hostId = '';

  const filteredHost = listHost.filter((item) => `${item?.full_name}`.toLowerCase().trim().includes(`${infosPipeDrive?.implantation_fees?.host_name}`.toLowerCase().trim()));

  if (filteredHost.length !== 0) {
    hostName = `${filteredHost?.[0]?.full_name}`;
    hostId = `${filteredHost?.[0]?.id}`;
  } else {
    const filteredDefault = listHost.filter((item) => `${item?.full_name}`.toLowerCase().trim() === 'a definir');
    hostName = `${filteredDefault?.[0]?.full_name}`;
    hostId = `${filteredDefault?.[0]?.id}`;
  }

  return { hostName, hostId };
}

export const getPartnerSelected = (listPartner: Array<RequestPartner>, infosPipeDrive: PipedriveOwnerInfo) => {
  let partnerName = '';
  let partnerId = '';

  const filteredPartner = listPartner.filter((item) => `${item?.user?.first_name} ${item?.user?.last_name}`.toLowerCase().trim().includes(`${infosPipeDrive?.partner_name}`.toLowerCase().trim()));

  if (filteredPartner.length !== 0) {
    partnerName = `${filteredPartner?.[0]?.user?.first_name} ${filteredPartner?.[0]?.user?.last_name}`;
    partnerId = `${filteredPartner?.[0]?.id}`;
  } else {
    const filteredDefaultPartner = listPartner.filter((item) => `${item?.user?.first_name} ${item?.user?.last_name}`.toLowerCase().trim() === 'a definir');
    partnerName = `${filteredDefaultPartner?.[0]?.user?.first_name} ${filteredDefaultPartner?.[0]?.user?.last_name}`;
    partnerId = `${filteredDefaultPartner?.[0]?.id}`;
  }

  return { partnerName, partnerId };
}

export const getCategorySelected = (listCategories: Array<GetCategoryLocationData>, infosPipeDrive: PipedriveOwnerInfo) => {
  let categoryName = '';
  let categoryId = '';

  const filteredCategory = listCategories.filter((item) => `${item?.code}`.toLowerCase().trim().includes(`${infosPipeDrive?.property_infos?.property_category}`.toLowerCase().trim()));

  if (filteredCategory.length !== 0) {
    categoryName = `${filteredCategory?.[0]?.code}`;
    categoryId = `${filteredCategory?.[0]?.id}`;
  } else {
    const filteredDefaultCategory = listCategories.filter((item) => ['adefinir'].includes(`${item?.code}`.toLowerCase().trim()));
    categoryName = `${filteredDefaultCategory?.[0]?.code}`;
    categoryId = `${filteredDefaultCategory?.[0]?.id}`;
  }

  return { categoryName, categoryId };
}
