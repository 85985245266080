import axios from 'axios';
import request from '../request';
import {
  AddressRequest,
  AddressDetail,
  Country,
  State,
  ViaCepResponse,
  IBGEresponse,
} from './types';

import { countries } from './countries';
import { states } from './states';
import viacepRequest from '../viacepRequest';
import ibgeRequestByUF from '../IBGErequest';

export const getStates = (): State[] => states;

export const getCountry = async (): Promise<Country[]> => countries;

export const getAddressByCep = async (cep: string): Promise<AddressRequest> => {
  const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
  return data as AddressRequest;
};

export const COUNTRY_BRAZIL = {
  name: 'Brasil',
  acronym: 'BR',
};

export const getAddressById = async (id: number): Promise<AddressDetail> => {
  const { data } = await request.get(`/account/address/${id}/`);
  return data as AddressDetail;
};

export const putAddress = async (id: number, addressUpdated: AddressDetail) => {
  const { data } = await request.put(`/account/address/${id}/`, {
    ...addressUpdated,
  });
  return data as AddressDetail;
};

export const patchAddress = async (id: number, addressUpdated: AddressDetail) => {
  const { data } = await request.patch(`/account/address/${id}/`, {
    ...addressUpdated,
  });
  return data as AddressDetail;
};

export const postAddress = async (newAddress: AddressDetail) => {
  const { data } = await request.post('/account/address/', {
    ...newAddress,
  });
  return data as AddressDetail;
};

export const deleteAddress = async (id: number) => {
  await request.delete(`/account/address/${id}/`);
};

export const getViaCeps = async (cep: string): Promise<ViaCepResponse> => {
  const { data } = await viacepRequest.get(`/${cep}/json/`);
  return data as ViaCepResponse;
};

export const getCitiesByUF = async (UF: string):Promise<IBGEresponse[]> => {
  const { data } = await ibgeRequestByUF.get(`/estados/${UF}/distritos/`, {
    params: {
      orderBy: 'nome',
    },
  });
  return data as IBGEresponse[];
};
