import { Container } from './styles';

interface ISpinner {
  loading: boolean;
}

const Spinner = ({ loading = false }: ISpinner) => (
  <Container loading={loading}>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5ZM2.8562 12.5C2.8562 17.55 6.95002 21.6438 12 21.6438C17.05 21.6438 21.1438 17.55 21.1438 12.5C21.1438 7.45002 17.05 3.3562 12 3.3562C6.95002 3.3562 2.8562 7.45002 2.8562 12.5Z" fill="url(#paint0_angular_612_27420)" />
      <defs>
        <radialGradient id="paint0_angular_612_27420" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12 12.5) rotate(90) scale(12)">
          <stop stopColor="#2B52A2" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  </Container>
);

export default Spinner;
