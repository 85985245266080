/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, ChangeEvent } from 'react';
import { v4 as uuid } from 'uuid';

import { DeleteForeverOutlined } from '@mui/icons-material';

import { FileAWSFormat, FileProps, FileReference, FileReferenceResponse } from '../../context/FileContext/types';
import { getFinancialTemplateTedOwner, postFinancialTemplateTedOwner } from '../../services/OwnerTedList/request';

import { useScreenResize } from '../../hooks/useSceenResize/useScreenResize';
import { useToast } from '../../context/ToastContext';
import { useFile } from '../../hooks/FileHook/useFile';

import Header from './Header';
import Filters from './Filters';
import TedListFab from './Header/Fab/TedListFab';
import { Text } from '../Text';
import FormButton from '../FormButton';
import Tooltip from '../Tooltip';

import {
  Card,
  Container,
  ContainerButton,
  ContainerUpload,
  ContentUpload,
  ContainerDeleteFile,
} from './styles';

const OwnerTedList = () => {
  const [file, setFile] = useState<FileProps | null>(null);
  const { isMobile } = useScreenResize(900);
  const toast = useToast();
  const { createFileReference, uploadFileToS3 } = useFile();

  const handleDownload = async (): Promise<void> => {
    try {
      await getFinancialTemplateTedOwner();
      toast.success('Template baixado com sucesso!');
    } catch (e) {
      toast.error('Erro no download do template.');
    }
  };

  const handleUploadFileToAWS = async (fileSelected: FileProps) => {
    let fileResponse: FileReferenceResponse | { uid: null } = { uid: null };

    try {
      const fileReference: FileReference = {
        category: 'owner_teds',
        name: fileSelected.name,
        content_type: fileSelected.MIMEtype,
      };

      const responseFile: FileReferenceResponse = await createFileReference(fileReference);

      const params: FileAWSFormat = {
        url: responseFile.storage.url,
        acl: responseFile.storage.fields.acl,
        content_type: fileReference.content_type,
        key: responseFile.storage.fields.key,
        AWSAccessKeyId: responseFile.storage.fields.AWSAccessKeyId,
        policy: responseFile.storage.fields.policy,
        signature: responseFile.storage.fields.signature,
        file: fileSelected.file,
        fileId: responseFile.uid,
      };

      await uploadFileToS3(fileSelected, params);

      fileResponse = {
        ...responseFile,
        storage: {
          ...responseFile.storage,
          fields: {
            ...responseFile.storage.fields,
          },
        },
      };

      return fileResponse;
    } catch (e: unknown) {
      toast.error('Não foi possível realizar o upload do arquivo de repasses.');
      return null;
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile({
        ...selectedFile,
        id: `${uuid()}`,
        uid: `${uuid()}`,
        name: selectedFile.name,
        size: selectedFile.size,
        readableSize: `${(selectedFile.size / 1024).toFixed(2)} KB`,
        MIMEtype: selectedFile.type,
        content_type: selectedFile.type,
        file: selectedFile,
      } as any);
    } else {
      toast.error('Selecione um arquivo no formato .csv');
    }
    event.target.value = '';
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error('Selecione um arquivo antes de enviar.');
      return;
    }

    try {
      const response = await handleUploadFileToAWS(file);
      if (response?.uid) {
        await postFinancialTemplateTedOwner(response.uid);
        toast.success('Arquivo enviado com sucesso!');
        setFile(null);
      } else {
        toast.error('Não foi possível realizar o upload do arquivo de repasses.');
      }
    } catch (e) {
      toast.error('Não foi possível realizar o upload do arquivo de repasses.');
    }
  };

  const ButtonDelete = () => (
    <Tooltip text="Remover arquivo" zIndex={1}>
      <ContainerDeleteFile onClick={() => setFile(null)}>
        <DeleteForeverOutlined style={{ color: '#E35B4C', fontSize: '25px' }} />
      </ContainerDeleteFile>
    </Tooltip>
  );

  const CardComponent = () => (
    <>
      <Card>
        <div>
          <Text $color="#000" $fontSize="16" $fontWeight="700">
            Template para lista de repasses
          </Text>
          <div style={{ margin: '16px' }} />
          <Text $color="#000" $fontSize="14" $fontWeight="500">
            Baixe o template para preenchimento dos repasses
          </Text>
        </div>
        <ContainerButton>
          <FormButton type="button" onClick={handleDownload}>
            {isMobile ? "Download" : "DOWNLOAD DO TEMPLATE"}
          </FormButton>
        </ContainerButton>
      </Card>
      <Card>
        <div>
          <Text $color="#000" $fontSize="16" $fontWeight="700">
            Atualização de valores de repasses
          </Text>
          <div style={{ margin: '16px' }} />
          <Text $color="#000" $fontSize="14" $fontWeight="500">
            Envie o arquivo preenchido com os repasses
          </Text>
        </div>
        <ContainerButton>
          <ContainerUpload className="outlined">
            {file && file?.name ? (
              <>
                <FormButton
                  type="button"
                  variant="outlined"
                  onClick={() => handleUpload()}
                >
                 {isMobile ? "Enviar" : "ENVIAR REPASSES"}
                </FormButton>
                <ContentUpload>
                  <span>{file.name}</span>
                  <ButtonDelete />
                </ContentUpload>
              </>
            ) : (
              <label htmlFor="file-upload">
                 {isMobile ? "Upload" : "UPLOAD DE REPASSES"}
              </label>
              )}

            <input
              id="file-upload"
              accept=".csv"
              type="file"
              onChange={handleFileChange}
            />
          </ContainerUpload>
        </ContainerButton>
      </Card>
    </>
  )

  if (isMobile) {
    return (
      <Container>
        <TedListFab />
        <CardComponent />
        {/* <Grid /> */}
        <Filters />
      </Container>
    );
  }

  return (
    <Container>
      <Header />
      {/* <Grid /> */}
      <CardComponent />
      <Filters />
    </Container >
  );
};

export { OwnerTedList };
