/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect,
  useState,
} from 'react';

import { getPropertiesOwner } from '../../services/Owner/request';

import { useOwnerCardProperties } from '../../hooks/OwnerPage/OwnerCardProperties/useOwnerCardProperties';
import { useViewMode } from '../../context/ViewMode/ViewMode';

import BannerSlider from './BannerSlider';
import OwnerReport from './OwnerReport';
import GridRevenue from './GridRevenue';
import PropertyList from './PropertyList';

import { Container } from './styles';

const OwnersPage: React.FC = () => {
  const { mode } = useViewMode();
  const { propertiesList, setPropertiesList } = useOwnerCardProperties();
  const [loadingCardProperties, setLoadingCardProperties] = useState<boolean>(true);

  const getProperties = async () => {
    setLoadingCardProperties(true);

    const validateMode = (mode && mode) || undefined;
    const values = await getPropertiesOwner(undefined, validateMode);
    setPropertiesList(values);
    setLoadingCardProperties(false);
  };

  useEffect(() => {
    getProperties();
  }, []);

  return (
    <>
      <BannerSlider />

      <Container>
        <PropertyList
          ownerProperties={propertiesList}
          isLoading={loadingCardProperties}
        />
        {/* <OpportunitiesSeazone /> */}

        {/* <FinancialSummary /> */}

        <GridRevenue propertiesList={propertiesList} isLoading={loadingCardProperties} />

        <OwnerReport propertiesList={propertiesList} />

        {/* <EventSection /> */}
      </Container>
    </>
  );
};

export default OwnersPage;
