import React, { ReactNode, Suspense } from 'react';
import { UseFormik } from '../../../../../utils/Formik/types';
import { SectionsVisibilityRules } from '../../../../../context/OnboardingContext/types';

import { useOnboardingHandover } from '../../../../../hooks/useOnboarding/useOnboardingHandover';

import { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import { SelectProps } from '../../../../Autocomplete/Autocomplete';
import CardTitle from '../../Cards/CardTitle';

import { Container } from './styles';

const FormOwnerInformation = React.lazy(() => import('../FormOwnerInformation'));
const FormPropertyInformation = React.lazy(() => import('../FormPropertyInformation'));
const FormFeesAndCommissions = React.lazy(() => import('../FormFeesAndCommissions'));
const FormInvoiceDetails = React.lazy(() => import('../FormInvoiceDetails'));
const FormFinalInformation = React.lazy(() => import('../FormFinalInformation'));

interface IFormSelected {
  formik: UseFormik<any>;
  ownerCountryIsBrazil: boolean;
  ownerAlreadyExists: boolean;
  defaultPartnerSelected: SelectOption;
  defaultHostSelected: SelectProps;
}

function FormSelected({
  formik,
  ownerCountryIsBrazil = true,
  ownerAlreadyExists = false,
  defaultPartnerSelected,
  defaultHostSelected,
}: IFormSelected) {
  const { visibility, totalErrors } = useOnboardingHandover();

  const FormComponent: Record<SectionsVisibilityRules, ReactNode> = {
    Section_Owner_Infos_Visible: visibility.Section_Owner_Infos_Visible ? <FormOwnerInformation formik={formik} ownerCountryIsBrazil={ownerCountryIsBrazil} ownerAlreadyExists={ownerAlreadyExists} defaultPartnerSelected={defaultPartnerSelected} /> : null,
    Section_Property_Infos_Visible: visibility.Section_Property_Infos_Visible ? <FormPropertyInformation formik={formik} /> : null,
    Section_Fees_Commissions_Visible: visibility.Section_Fees_Commissions_Visible ? <FormFeesAndCommissions formik={formik} defaultHostSelected={defaultHostSelected} /> : null,
    Section_Invoice_Details_Visible: visibility.Section_Invoice_Details_Visible ? <FormInvoiceDetails formik={formik} /> : null,
    Section_Final_Infos_Visible: visibility.Section_Final_Infos_Visible ? <FormFinalInformation formik={formik} ownerCountryIsBrazil={ownerCountryIsBrazil} /> : null,
  };

  const renderFormSection = (sectionKey: SectionsVisibilityRules, title: string, totalErrorsInForm: number) => (
    <>
      <CardTitle
        title={title}
        sectionVisible={sectionKey}
        totalErrorsInForm={totalErrorsInForm}
        disableCounterErrors={['Section_Owner_Infos_Visible', 'Section_Invoice_Details_Visible' as SectionsVisibilityRules].includes(sectionKey) && ownerAlreadyExists}
      />
      <Suspense fallback={<div>Carregando...</div>}>
        <Container>
          {FormComponent[sectionKey]}
        </Container>
      </Suspense>
    </>
  );

  return (
    <>
      {renderFormSection('Section_Owner_Infos_Visible', 'Dados Pessoais', totalErrors.Section_Owner_Infos)}
      {renderFormSection('Section_Property_Infos_Visible', 'Informações do Imóvel', totalErrors.Section_Property_Infos)}
      {renderFormSection('Section_Fees_Commissions_Visible', 'Taxas e Comissões', totalErrors.Section_Fees_Commissions)}
      {renderFormSection('Section_Invoice_Details_Visible', 'Dados para Nota Fiscal', totalErrors.Section_Invoice_Details)}
      {renderFormSection('Section_Final_Infos_Visible', 'Informações Finais', totalErrors.Section_Final_Infos)}
    </>
  );
}

export default FormSelected;
