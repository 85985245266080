import React, { useEffect } from 'react';

import OnboardingPage from '../../components/OnboardingPage';

import { Container } from './styles';

const Onboarding: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Onboarding';
  }, []);

  return (
    <OnboardingPage />
  );
};

export default Onboarding;
