import { AccountOwnerOnboardingProps } from "../../services/Onboarding/types";
import { compareList } from "../../utils/Sorting";
import { Order, OrderBy, SectionsOptions, SectionsVisibilityProps } from "./types";

export const initialValuesVisibility: SectionsVisibilityProps = {
  'Section_Owner_Infos_Visible': false,
  'Section_Property_Infos_Visible': false,
  'Section_Fees_Commissions_Visible': false,
  'Section_Invoice_Details_Visible': false,
  'Section_Final_Infos_Visible': false,
}

export const initialValuesTotalErrors: Record<SectionsOptions, number> = {
  Section_Owner_Infos: 0,
  Section_Property_Infos: 0,
  Section_Fees_Commissions: 0,
  Section_Invoice_Details: 0,
  Section_Final_Infos: 0,
}

export const translateHeaderLabel: Record<string, string> = {
  'Proprietário': 'name',
  'PERSON ID': 'pipedrive_person_id',
  'Imóveis Ativos': 'property_codes',
};

export const sortAllOwnersList = (
  listItems: AccountOwnerOnboardingProps[],
  orderBy: OrderBy,
  order: Order,
): AccountOwnerOnboardingProps[] => {
  const sorted = listItems.slice();

  switch (orderBy) {
    case 'name':
      sorted.sort((a, b) =>
        compareList(a.name.toLowerCase().trim(), b.name.toLowerCase().trim(), order)
      );
      break;

    case 'pipedrive_person_id':
      sorted.sort((a, b) =>
        compareList(a.pipedrive_person_id, b.pipedrive_person_id, order)
      );
      break;
      
    case 'property_codes':
      sorted.sort((a, b) => {
        const firstCodeA = a.property_codes?.[0]?.toLowerCase() || "";
        const firstCodeB = b.property_codes?.[0]?.toLowerCase() || "";
        return compareList(firstCodeA, firstCodeB, order);
      });
      break;

    default:
      return sorted;
  }

  return sorted;
};


export const sortPartialOwnersList = (
  listItems: AccountOwnerOnboardingProps[],
  orderBy: OrderBy,
  order: Order,
  order_start_position: number,
  order_end_position: number,
): AccountOwnerOnboardingProps[] => {
  let sorted: AccountOwnerOnboardingProps[] = listItems.slice();

  const start = order_start_position;
  const end = order_end_position;

  const beforeSort = listItems.slice(0, start);
  const toSort = listItems.slice(start, end);
  const afterSort = listItems.slice(end + 1 > listItems.length
    ? listItems.length : end + 1, listItems.length);

  switch (orderBy) {
    case 'name':
      sorted = toSort.map((item) => item).sort((a, b) => compareList(
        a.name.toLowerCase().trim(), b.name.toLowerCase().trim(), order,
      ));
      break;

    case 'pipedrive_person_id':
      sorted = toSort.map((item) => item).sort((a, b) => compareList(
        a.pipedrive_person_id, b.pipedrive_person_id, order,
      ));
      break;
    
    case 'property_codes':
      sorted =  toSort.sort((a, b) => {
        const firstCodeA = a.property_codes?.[0]?.toLowerCase() || "";
        const firstCodeB = b.property_codes?.[0]?.toLowerCase() || "";
        return compareList(firstCodeA, firstCodeB, order);
      });
      break;

    default:
      return sorted;
  }

  return beforeSort.concat(sorted).concat(afterSort);
};
