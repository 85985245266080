import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  height: 100vh;

  .labelClassName {
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 16.94px !important;
    text-align: left !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  }

  .MuiFormControlLabel-root {
    margin: 0 !important; 
  }

  input {
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    text-align: left !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;

    ::placeholder {
      font-family: 'Inter' !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 19.36px !important;
      letter-spacing: -0.02em !important;
      text-align: left !important;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 16px;

  @media(max-width: 900px) {
    padding: 0 2px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  background: white;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  bottom: 0px;
  right: -30px;
  z-index: 10;
  padding: 16px 0; 
  button {
    min-width: 426px;
    max-width: 426px;
    max-height: 46px;
  }
`;
