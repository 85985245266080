import styled, { keyframes } from 'styled-components';

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Section = styled.section`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.blue._90.hex()};
`;

export const Form = styled.form<{ isShowingErrorMessage?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 80%;
  gap: 30px;
  padding: 0 16px 16px 16px;

  .field-deal {
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
    line-height: 16.94px !important;
  }

  @media(max-width: 900px) {
    flex-direction: column;
    max-width: 100%;
    height: auto;
  }
`;

export const TextFieldSyncPipedrive = styled.div`
  width: 289.5px;
  /* height: 60px; */
  margin: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  
  .MuiOutlinedInput-root {
    width: 100% !important;
    border: 0 !important;
    box-sizing: border-box !important;
    margin: 0px !important;
    padding: 8px 16px !important;
    height: 38px !important;

    input {
      padding: 0px 0 3px 0 !important;
    }

    &:focus, &:active, &:hover fieldset {
      border: solid 1px ${({ theme }) => theme.palette.grey._425.hex()} !important;
    }
  }
  
  .MuiInputBase-input {
    width: 100% !important;
    border: 0 !important;
    box-sizing: border-box !important;
    padding: 0px !important;
    margin: 0px !important;

    :focus, :active, :hover {
      border: none !important;
    }

    ::placeholder {
      font-family: 'Inter' !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 22px !important; 
      color: #969696 !important;
    }
  }

  @media(max-width: 900px) {
    width: 100%;
  }
`;

export const ButtonSyncPipedrive = styled.div`
  button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 152px;
    min-height: 40px !important;
    margin: 0px !important;
    padding: 10px 33px !important;
    gap: 10px;
  }

  @media(max-width: 900px) {
    width: 100%;
  }
`;

export const Divider = styled.div`
  height: 55px;
  width: 1px;
  background: ${({ theme }) => theme.palette.blue._90.hex()};
`;

export const Title = styled.h2`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  line-height: 21.78px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  padding: 20px 0 12px 16px;
`;

export const CircleLoading = styled.div<{
  size: string;
}>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: 3px solid ${({ theme }) => theme.palette.blue._910.hex()};
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: ${spin} 1s linear infinite;

  ::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 128px;
    height: 130px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color:  ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;

export const CardLoading = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;

  p {
    padding-top: 20px;
    font-size: 20px;
    color: ${({ theme }) => theme.palette.grey._800.hex()}
  }
`;
