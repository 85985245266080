import { useRef } from 'react';

import { useUser } from '../../../context/UserContext';
import { analyticsEvent } from '../../../pages/Partners/utils/analytics';
import { useScreenResize } from '../../../hooks/useSceenResize/useScreenResize';

import { bannersMobileList } from './banners_mobile';
import { bannersDeskList } from './banners_desk';

import SimpleSlider from '../../Carousel/SimpleSlider';

import {
  ContainerSlider,
  Container,
} from './styles';

const BannerSlider = () => {
  const { userInformation } = useUser();
  const { isMobile } = useScreenResize(900);
  const sliderRef = useRef<any>(null);

  const handleAddAnalytics = (name: string) => {
    analyticsEvent({
      name,
      userInformation,
    });
  };

  if (isMobile) {
    return (
    <ContainerSlider>
        <SimpleSlider
          sliderRef={sliderRef}
          settings={{
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToScroll: 1,
            draggable: true,
            autoplay: true
          }}
        >
          {bannersMobileList.map((item) => (
            <Container key={item.id} background={item.background}>
              <a onClick={() => handleAddAnalytics(item.infos_analytics.name)} href={item.link} target="_blank">
                <img src={item.image} alt={item.alt} />
              </a>
            </Container>
          ))}
        </SimpleSlider>
      </ContainerSlider>
    );
  };

  return (
    <ContainerSlider>
      <SimpleSlider
        sliderRef={sliderRef}
        settings={{
          dots: true,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToScroll: 1,
          draggable: true,
          autoplay: true
        }}
      >
        {bannersDeskList.map((item) => (
          <Container key={item.id} background={item.background}>
            <a onClick={() => handleAddAnalytics(item.infos_analytics.name)} href={item.link} target="_blank">
              <img src={item.image} alt={item.alt} />
            </a>
          </Container>
        ))}
      </SimpleSlider>
    </ContainerSlider>
  );
};

export default BannerSlider;
