import styled from 'styled-components';

export const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 0 16px 100px 16px;
`;

export const Title = styled.h2`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  line-height: 21.78px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  padding: 20px 0 12px 0px;
`;

export const CardOwnerSelected = styled.div`
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background: ${({ theme }) => theme.palette.white._350.hex()};
  border-radius: 3px;
  p, button {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.palette.grey._760.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._6};
    cursor: pointer
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: none;
    background-color: ${({ theme }) => theme.palette.grey._380.hex()};
    color: ${({ theme }) => theme.palette.white._350.hex()};
  }
`;

export const SearchContainer = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  gap: 17px;
  
  @media(max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  max-width: 466px;
  width: 100%;
  
  .MuiOutlinedInput-root {
    width: 100% !important;
    max-height: 40px !important;
    border: 0 !important;
    box-sizing: border-box !important;
    margin: 0px !important;
    padding: 8px 16px !important;
    height: 38px !important;
    letter-spacing: normal !important;

    input {
      padding: 0px 0 3px 0 !important;
      border-radius: 0px !important;
    }

    &:focus, &:active, &:hover  fieldset {
      border: solid 1px ${({ theme }) => theme.palette.grey._425.hex()} !important;
    }
  }
  
  .MuiInputBase-input {
    width: 100% !important;
    border: 0 !important;
    box-sizing: border-box !important;
    padding: 0px !important;
    margin: 0px !important;

    :focus, :active, :hover {
      border: none !important;
    }

    ::placeholder {
      font-family: 'Inter' !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 22px !important; 
      color: ${({ theme }) => theme.palette.blue._250.hex()};
    }
  }
`;

export const ButtonContainer = styled.div`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 119px;
    min-height: 40px !important;
    margin: 0px !important;
    padding: 10px 33px !important;
    gap: 10px;
  }

  @media(max-width: 900px) {
    button {
      min-width: 100%;
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100px;
  margin-top: 16px;
  border: ${({ theme }) => theme.palette.grey._375.hex()} 1px solid;
  border-radius: 10px;
  h1 {
    margin-top: 10px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
`;

export const ResultsText = styled.div<{ isLast?: boolean }>`
  margin-top: 18px;
  width: 100%;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.palette.white._600.hex()};
  }
  p{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.grey._765.hex()};
    margin-bottom: 18px;
  }
  border-bottom: ${({ isLast }) => !isLast && '0.5px solid #C1C1C1'} ;
`;

export const TitleContainer = styled.div`
  display: flex;
  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
`;
