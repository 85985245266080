import moment from 'moment';

import { FormikProps } from "./types";
import { CreateCompleteUser, UpdateCompleteUser } from '../../../../services/User/types';

import { clearString, formatPhoneToSubmit, generateRandomPassword } from "./utils";
import { formatCNPJToPost, formatCPFtoSubmit } from '../../../../utils/Formatter';
import { normalizeState } from '../../../../hooks/useIBGE/utils';

import { normalizeCountry } from '../../../../services/Address/countries';
import { postAddress, patchAddress } from '../../../../services/Address/request';
import { postUser, patchAccountUser } from '../../../../services/User/request';
import { postOwner, patchOwner } from '../../../../services/Owner/request';

export const createOwnerAddress = async (values: FormikProps, stateMappings: Record<string, string[]>) => {
  const addressResponse = await postAddress({
    postal_code: values.owner_address_postal_code,
    street: clearString(values.owner_address_street),
    number: values.owner_address_number,
    neighborhood: clearString(values.owner_address_neighborhood),
    city: values.owner_address_city,
    state: normalizeState(stateMappings, values.owner_address_state),
    country: normalizeCountry(values.owner_address_country),
    complement: values.owner_address_complement,
  });
  return addressResponse;
};

export const updateOwnerAddress = async (addressId: number, values: FormikProps, stateMappings: Record<string, string[]>) => {
  const addressResponse = await patchAddress(addressId, {
    postal_code: values.owner_address_postal_code,
    street: clearString(values.owner_address_street),
    number: values.owner_address_number,
    neighborhood: clearString(values.owner_address_neighborhood),
    city: values.owner_address_city,
    state: normalizeState(stateMappings, values.owner_address_state),
    country: normalizeCountry(values.owner_address_country),
    complement: values.owner_address_complement,
  });
  return addressResponse;
};

export const createUser = async (values: FormikProps, mainAddress: number) => {
  const pass = generateRandomPassword();

  const userInfos: CreateCompleteUser = {
    is_staff: false,
    is_active: true,
    trading_name: '',
    corporate_name: '',
    main_role: 'Owner',
    email: values.email,
    gender: 'Not informed',
    first_name: values.name,
    last_name: values.lastname,
    phone_number1: formatPhoneToSubmit(values.phone),
    main_address: mainAddress,
    pipedrive_person_id: values.personID,
    is_individual: values.typePerson === 'individual',
    birth_date: moment(values.born).format('YYYY-MM-DD'),
    cpf: values.typePerson === 'individual' ? formatCPFtoSubmit(values.cpf) : '',
    cnpj: values.typePerson !== 'individual' ? formatCNPJToPost(values.cnpj) : '',
    password: pass, // Hard code: enviando uma senha temporária para não quebrar o fluxo de inserção de um novo user na tabela account_user. Assim que o usuário é criado, essa senha é alterada por uma outra senha gerada pelo backend 
    password_confirmation: pass,
  };

  const userResponse = await postUser(userInfos);
  return userResponse;
};

export const updateUser = async (userId: number, values: FormikProps) => {
  const userInfos: UpdateCompleteUser = {
    email: values.email,
    first_name: values.name,
    last_name: values.lastname,
    phone_number1: formatPhoneToSubmit(values.phone),
    pipedrive_person_id: values.personID,
    is_individual: values.typePerson === 'individual',
    birth_date: moment(values.born).format('YYYY-MM-DD'),
    cpf: values.typePerson === 'individual' ? formatCPFtoSubmit(values.cpf) : '',
    cnpj: values.typePerson !== 'individual' ? formatCNPJToPost(values.cnpj) : '',
  };

  const userResponse = await patchAccountUser(userId, userInfos);
  return userResponse;
};

export const createOwner = async (values: FormikProps, userId: number, mainAddress: number) => {
  const ownerResponse = await postOwner({
    user: userId,
    invoice_address: mainAddress,
    profession: clearString(
      `${values.profession}`.trim() === '' ? 'Não informado' : values.profession,
    ),
    nationality: clearString(
      `${values.nationality}`.trim() === '' ? 'Não informado' : values.nationality,
    ),
    marital_status: values.marital_status,
    is_partner_indication: values.isPartnerIndication,
    referrer_partner: ['undefined', '', 'null'].includes(`${values.indicationName}`.trim()) ? undefined : values.indicationName,
  });

  return ownerResponse;
}

export const updateOwner = async (ownerId: number, values: FormikProps) => {
  const ownerResponse = await patchOwner(ownerId, 
    {
      profession: clearString(
        `${values.profession}`.trim() === '' ? 'Não informado' : values.profession,
      ),
      nationality: clearString(
        `${values.nationality}`.trim() === '' ? 'Não informado' : values.nationality,
      ),
      marital_status: values.marital_status,
      is_partner_indication: values.isPartnerIndication,
      referrer_partner: ['undefined', '', 'null'].includes(`${values.indicationName}`.trim()) ? undefined : values.indicationName,
  });

  return ownerResponse;
}
