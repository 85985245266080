import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import moment from 'moment';
import ReactGA from 'react-ga4';

import { Edit, WhatsApp, AccessTime, Check } from '@mui/icons-material';

import CloseIcon from '@mui/icons-material/Close';
import copyIcon from '../../../assets/icons/generals/copy.svg';
import CopyBlueIcon from '../../../assets/icons/generals/copy-blue.svg';
import ShareIcon from '../../../assets/icons/controll/ShareIcon.svg';
import FormsIcon from '../../../assets/icons/controll/FormsIcon.svg';
import whatsapp from '../../../assets/icons/controll/whatsapp.svg';
import CleaningIcon from '../../../assets/icons/controll/cleanup.svg';

import {
  Checkin,
  ChecklistDataProps,
  Checkout,
  RequestEvent,
} from '../../../services/Control/types';
import { PatchGuestForm } from '../../../services/Guest/types';

import {
  formatDateToShow,
  formatedDateToShow,
  removeSecondsHourToShow,
} from '../../../utils/Formatter';
import { queryClient } from '../../../utils/QueryClient/QueryClient';

import { patchGuest } from '../../../services/Guest/request';
import {
  putChecklist,
  postChecklist,
  putCheckin,
  getCheckin,
  postCheckin,
  getCheckout,
  putCheckout,
  postCheckout,
  getClearning,
} from '../../../services/Control/request';

import { useChecklist } from '../../../context/ControllerPage/ChecklistContext';
import { useToast } from '../../../context/ToastContext';
import { useUser } from '../../../context/UserContext';

import { CheckList } from '../CheckList/CheckList';
import { HotBed } from './pins/Pins';
import Tooltip from '../../Tooltip';
import LateralModal from '../LateralModal/LateralModal';
import CountrySelector from '../../CountrySelector/CountrySelector';

import {
  BottomContainer,
  CardName,
  CheckColor,
  ClientNameTitle,
  ClientPhone,
  CloseButton,
  ContentContainer,
  CopyContainer,
  FirstInfoContainer,
  InfoBottomContainer,
  InfoContainer,
  LeftContainer,
  ModalBackdrop,
  ModalContainer,
  ModalHeaderContainer,
  PropertyAddress,
  PropertyContainer,
  PropertyTitle,
  ReservationInfoContainer,
  ReserveDate,
  ReserveTime,
  AddressContainer,
  ButtonContainer,
  PencilButton,
  TextFieldContainer,
  BlockingAlertContainer,
  StyledImage,
  ModalButton,
  StyledIcon,
} from './style';

interface Props {
  handlePrevent?: Function | any;
  onClose?: Function | any;
  checkin?: boolean;
  checkout?: boolean;
  localData?: RequestEvent[];
  // clearning?: boolean;
  hot?: boolean;
  recive?: boolean;
  onOpenLateralModal: Function | any;
  onCloseLateralModal: Function | any;
  lateralModal: boolean;
  checkList: boolean;
  setCheckList: Function;
  reservation: RequestEvent | undefined;
  mobile: boolean;
  statusContact: boolean;
  setReservation: Function;
  updateLocalData: Function;
  reservationId: number | undefined;
  setStatusContact: (a: boolean) => void;
}

const handleStopEvent = (event: Event) => {
  event.stopPropagation();
};

const copyToClipboard = (text: string | EventTarget | any) => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

const copyAllInformation = (
  codeReservation: string,
  propertyCode: string,
  checkInDate: string,
  checkOutDate: string,
  guestName: string[],
) => {
  const guests = guestName.join(' ');
  navigator.clipboard.writeText(
    `Código de reserva: ${codeReservation}\nCódigo do imóvel: ${propertyCode}\nData de Check-in: ${checkInDate}\nData de Check-out: ${checkOutDate}\nNome do hóspede: ${guests}`,
  );
};

const ShareAddress = () => (
  <Tooltip text="Copiar mensagem de endereço">
    <StyledImage src={ShareIcon} className="shareAddress" alt="icone de compartilhar endereço" />
  </Tooltip>
);

const Copy = () => <img src={copyIcon} alt="icone de copiar" className="copyIcon" />;

const Modal = ({
  handlePrevent,
  onClose,
  hot,
  recive,
  mobile,
  checkin,
  checkout,
  checkList,
  // clearning,
  lateralModal,
  setCheckList,
  statusContact,
  reservationId,
  setReservation,
  updateLocalData,
  localData,
  setStatusContact,
  onOpenLateralModal,
  onCloseLateralModal,
  reservation = undefined,
}: Props) => {
  const { userInformation } = useUser();
  const [isCleaningModal, setIsCleaningModal] = useState(false);
  const nickname = userInformation?.nickname;
  const fullName = `${userInformation?.first_name} ${userInformation?.last_name}`;
  const navigate = useNavigate();
  const { finishChecklist, setFinishChecklist } = useChecklist();
  const toast = useToast();

  const [isEditPhoneNumber, setIsEditPhoneNumber] = useState<Boolean>(false);
  const [wasChecked, setWasChecked] = useState<Boolean>(false);

  const formik = useFormik({
    initialValues: {
      phoneNumber: reservation?.phone || '',
    },

    onSubmit: async (values) => {
      try {
        if (reservation?.guest_id) {
          const data: PatchGuestForm = {
            user: {
              phone_number1: values.phoneNumber,
            },
          };
          await patchGuest(Number(reservation.guest_id), data);
          setReservation({ ...reservation, phone: values.phoneNumber });
          queryClient.invalidateQueries('event-list');
          queryClient.invalidateQueries('event-checkin');
          queryClient.invalidateQueries('event-checkout');
        }
        setIsEditPhoneNumber(!isEditPhoneNumber);
      } catch (err) {
        toast.error('Não foi possível atualizar o telefone');
      }
    },
  });

  const formatPhoneNumber = (phone: string) =>
    `${phone}`
      .replace('+055', '+55')
      .replace('+55', '55')
      .replace(/\s+/g, '')
      .replace('(', '')
      .replace(')', '');

  const phoneNumber = formatPhoneNumber(reservation?.phone || '');

  const guestName = reservation?.name?.split(' ') || [''];
  const guestPhoneNumer = reservation?.phone;
  const code = reservation?.property_code;
  const city = reservation?.city || '';
  const splittedCheckInDate = reservation?.check_in_date.split('-') || 'dd/mm';
  const formattedCheckInDate = `${splittedCheckInDate[2]}/${splittedCheckInDate[1]}`;
  const splittedCheckOutDate = reservation?.check_out_date.split('-') || 'dd/mm';
  const formattedCheckOutDate = `${splittedCheckOutDate[2]}/${splittedCheckOutDate[1]}`;
  const checkInTime = reservation?.check_in_time.slice(0, 5) || '00:00';
  const propertyCode = reservation?.property_code || '---';

  let preCheckinMessage = '';
  let checkinMessage = '';
  let checkoutMessage = '';
  let feedBackMessage = '';

  preCheckinMessage = `Olá ${guestName[0]}, Tudo bem? \nMe chamo ${nickname} e serei responsável pela sua reserva no ${code}. Acompanharei sua hospedagem de ${formattedCheckInDate} a ${formattedCheckOutDate} aqui em ${city}. ✈\n\nBoas vindas! 😊\n\nTenho algumas dúvidas. Poderia, por gentileza, me ajudar? Assim, organizamos a equipe envolvida.\n\n📌 Qual seria o horário de previsão de sua chegada? A *liberação de entradas nos imóveis ocorre a partir das 15h*.\n📌 Por favor, *reconfirme a quantidade de pessoas* (adultos e crianças).\n📌 Para oferecer acesso ao imóvel, precisarei de um *documento com foto* de todos que estarão no local. Poderia compartilhar comigo?\n\nAguardo suas informações.\n\nLembrete: entradas após as 20h possuem o acréscimo da taxa de conveniência, nos consulte se for o seu caso.\n\nNos vemos em breve!`;
  preCheckinMessage = encodeURIComponent(preCheckinMessage);

  checkinMessage = `Olá ${guestName[0]}, Tudo bem?\nHoje é o dia de sua chegada. Boas-vindas a ${city}! 💼\n\nPosso confirmar a sua entrada para às *${checkInTime}?*\nSe houver alguma alteração, peço que me comunique com antecedência para que eu reorganize a equipe que irá te receber.\n\nQuando chegar na cidade, me comunique. Caso se sinta a vontade, compartilhe sua localização em tempo real.\n\nTe desejo uma ótima estada!`;
  checkinMessage = encodeURIComponent(checkinMessage);

  checkoutMessage = `Olá ${guestName[0]}, Tudo bem?\nTenho anotado que amanhã é o dia de sua partida. Passou tão rápido.\nEspero que esteja aproveitando bastante! 🌴\nPrecisamos combinar sua saída. Poderia informar o horário previsto? A permanência no imóvel é permitida até as 11h.\nPara estender o horário ou prolongar sua estadia, consulte valores e disponibilidade diretamente com a central de atendimento pelo link: https://wa.me/554891138912\nAguardo seu retorno 😊`;
  checkoutMessage = encodeURIComponent(checkoutMessage);

  feedBackMessage = `${guestName[0]}, foi um grande prazer ter você conosco! Esperamos que sua estadia tenha sido incrível e desejamos um ótimo retorno para casa! 🧳✨\n\nComo conversamos, temos duas avaliações e _sua opinião faz toda a diferença para nós._\n\nNos pŕoximos dias, você receberá um e-mail da plataforma onde fez sua reserva. _Sua avaliação com relação ao meu atendimento é muito importante para eu estar sempre aprimorando minha operação_, e seria maravilhoso ver meu nome como _Anfitrião Superhost com uma nota 5_ ⭐. Ficaria super feliz com sua colaboração!\n\nEnquanto isso, que tal nos ajudar com nossa avaliação interna da Seazone? Ela nos ajuda a fazer melhorias imediatas para garantir que tudo esteja perfeito da próxima vez! É rapidinho, basta clicar no link abaixo:\nhttps://app.pipefy.com/public/form/snx7xQOw/?franqueado=${propertyCode}&copy_of_c_digo_do_im_vel=${reservation?.code}\n\nAgradeço mais uma vez, e espero ver você em breve! 🌟`;
  feedBackMessage = encodeURIComponent(feedBackMessage);

  const checkinWhatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${preCheckinMessage}`;
  const checkoutWhatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${checkoutMessage}`;
  const feedBackWhatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${feedBackMessage}`;

  const address = reservation?.address ? `${reservation.address}, ${reservation?.city || ''}`.replaceAll(' ', '+') : ' ';
  const addressCheckInMessage = `Olá *${guestName[0]}*, segue a localização do imóvel: Link: *https://www.google.com/maps/place/${address}*`;

  const handleCloseChecklist = () => {
    setCheckList(false);
  };

  const handleRedirectToPage = (link: string) => {
    window.open(link, '_blank');
  };

  const handleRedirectInSapron = (path: string) => {
    navigate(path);
  };

  const handleWasContactedCheckin = useMutation(async (linkRedirect: string) => {
    try {
      handleRedirectToPage(linkRedirect);
      const resultCheckin = await getCheckin(reservationId);
      if (resultCheckin.length > 0 && resultCheckin[0].id) {
        if (reservationId) {
          await putCheckin(
            { was_contacted: true, reservation: reservationId } as Checkin,
            resultCheckin[0].id,
          );

          const newLocalData = localData?.map((item: any) =>
            item.id === reservation?.id && item.type === reservation?.type
              ? { ...item, was_contacted: true }
              : item,
          );

          updateLocalData(newLocalData);
        }
      } else {
        await postCheckin({ was_contacted: true, reservation: reservationId } as Checkin);
        setReservation({
          ...reservation,
          checklist: { concluded: false, notes: '', questionList: [] },
        });

        const newLocalData = localData?.map((item: any) =>
          item.id === reservation?.id && item.type === reservation?.type
            ? {
                ...item,
                was_contacted: true,
                checklist: { concluded: false, notes: '', questionList: [] },
              }
            : item,
        );

        updateLocalData(newLocalData);
      }
    } catch (error) {
      //
    }
  });

  const handleWasContactedCheckout = useMutation(async (linkRedirect: string) => {
    try {
      handleRedirectToPage(linkRedirect);
      const resultCheckout = await getCheckout(reservationId);
      if (resultCheckout?.length > 0 && resultCheckout[0]?.id) {
        if (reservationId) {
          await putCheckout(
            { was_contacted: true, reservation: reservationId } as Checkout,
            resultCheckout[0]?.id,
          );

          const newLocalData = localData?.map((item: any) =>
            item.id === reservation?.id && item.type === reservation?.type
              ? {
                  ...item,
                  was_contacted: true,
                }
              : item,
          );

          updateLocalData(newLocalData);
        }
      } else {
        await postCheckout({ was_contacted: true, reservation: reservationId } as Checkout);
        setReservation({
          ...reservation,
          checklist: { concluded: false, notes: '', questionList: [] },
        });

        const newLocalData = localData?.map((item: any) =>
          item.id === reservation?.id && item.type === reservation?.type
            ? {
                ...item,
                was_contacted: true,
                checklist: { concluded: false, notes: '', questionList: [] },
              }
            : item,
        );

        updateLocalData(newLocalData);
      }
    } catch (error) {
      //
    }
  });

  const concludeCheckList = useMutation(async (newConcluded: boolean) => {
    try {
      if (reservationId && reservation) {
        if (reservation.was_contacted) {
          const checklistData: ChecklistDataProps = {
            concluded: newConcluded,
            notes: '',
            questionList: [],
          };

          if (reservation.checklist !== undefined) {
            await putChecklist(
              reservation.id,
              checkin,
              checkout,
              // clearning,
              checklistData,
            );
          } else {
            await postChecklist(
              reservation.id,
              checkin,
              checkout,
              // clearning,
              checklistData,
            );
          }

          const newReservationData = {
            ...reservation,
            checklist: {
              ...reservation.checklist,
              questionList: [],
              concluded: newConcluded,
            },
          };

          const newLocalData = localData?.map((item: any) =>
            item.id === reservation?.id && item.type === reservation?.type
              ? { ...item, status: 'COMPLETE', checklist: checklistData }
              : item,
          );

          updateLocalData(newLocalData);

          setFinishChecklist(!finishChecklist);
          setReservation(newReservationData);
          toast.success('Checklist finalizado com sucesso!');
        } else {
          toast.error('Preencha as informações de horário de entrada para concluir');
        }
      }
    } catch (error) {
      setFinishChecklist(!finishChecklist);
      toast.error('Erro ao finalizar checklist');
    }
  });

  const handleConcludeChecklist = async (value: boolean, linkOpen?: string) => {
    if (linkOpen && linkOpen?.length > 1) {
      handleRedirectToPage(linkOpen);
    }
    await concludeCheckList.mutateAsync(value);
    onClose();
    ReactGA.event('Concluir card', {
      category: `${userInformation?.main_role}-${userInformation?.user_id}-${
        userInformation?.nickname || userInformation?.first_name
      }`,
      action: 'Clique no botão',
    });
  };

  const handleUndoConcludedChecklist = async () => {
    await concludeCheckList.mutateAsync(false);
    onClose();
    ReactGA.event('Desconcluir card', {
      category: `${userInformation?.main_role}-${userInformation?.user_id}-${
        userInformation?.nickname || userInformation?.first_name
      }`,
      action: 'Clique no botão',
    });
  };

  const handleType = () => {
    if (reservation) {
      if (checkin) {
        return formatedDateToShow(reservation.check_in_date);
      }
      if (checkout) {
        return formatedDateToShow(reservation.check_out_date);
      }
    }
    return '';
  };

  const handleTime = () => {
    if (reservation) {
      if (checkin) {
        return removeSecondsHourToShow(reservation.check_in_time);
      }
      if (checkout) {
        return removeSecondsHourToShow(reservation.check_out_time);
      }
    }
    return '';
  };

  const calcDays = () =>
    moment(reservation?.check_out_date, 'YYYY-MM-DD').diff(
      moment(reservation?.check_in_date, 'YYYY-MM-DD'),
      'days',
    );

  const handleMessageWhatsapp = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=${formatPhoneNumber(reservation?.phone || '')}`,
      '_blank',
    );
    ReactGA.event('Enviar mensagem Whatsapp', {
      category: `${userInformation?.main_role}-${userInformation?.user_id}-${
        userInformation?.nickname || userInformation?.first_name
      }`,
      action: 'Clique no botão',
    });
  };

  type CardType = 'Checkin' | 'Checkout';

  const handleEventSendInitialMessage = (card: CardType) => {
    if (card === 'Checkin') {
      handleWasContactedCheckin.mutateAsync(checkinWhatsappLink);
    } else if (card === 'Checkout') {
      handleWasContactedCheckout.mutateAsync(checkoutWhatsappLink);
    }

    ReactGA.event(`${card} - Enviar mensagem inicial`, {
      category: `${userInformation?.main_role}-${userInformation?.user_id}-${
        userInformation?.nickname || userInformation?.first_name
      }`,
      action: 'Clique no botão',
    });
  };

  const handleEventAlignChecking = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${checkinMessage}`,
      '_blank',
    );
  };

  const handleOpenCleaningInformationModal = () => {
    setIsCleaningModal(true);
    onOpenLateralModal();
  };

  const handleOpenFillInformationModal = () => {
    if (isCleaningModal === true) {
      setIsCleaningModal(false);
    }
    onOpenLateralModal();
  };

  function handleSendAddressToClipBoard() {
    copyToClipboard(addressCheckInMessage);
    toast.success('Copiado para área de transferência');
  }

  function handleSendReservationCodeToClipboard() {
    const reservationCode = reservation?.code || '';
    copyToClipboard(reservationCode);
    toast.success('Copiado para área de transferência');
  }

  function handleSendReservationDetailsToClipboard() {
    copyAllInformation(
      reservation?.code || '',
      propertyCode,
      reservation?.check_in_date
        ? formatDateToShow(moment(reservation.check_in_date).toDate())
        : '',
      reservation?.check_out_date
        ? formatDateToShow(moment(reservation.check_out_date).toDate())
        : '',
      guestName || [''],
    );
    toast.success('Copiado para área de transferência');
  }

  useEffect(() => {
    async function getWasChecked() {
      const resultCleaning = await getClearning(reservation?.id);
      setWasChecked(resultCleaning[0]?.was_checked || false);
    }
    if (checkout) {
      getWasChecked();
    }
  }, [reservation]);

  return (
    <>
      {checkList && (
        <CheckList
          reservationId={reservation?.id}
          code={reservation?.id}
          mobile={mobile}
          closeChecklist={handleCloseChecklist}
          handlePrevent={handlePrevent}
          checkin={checkin}
          checkout={checkout}
        />
      )}
      <ModalContainer checkList={checkList} onClick={handlePrevent}>
        <CloseButton data-cy="btn-close-modal" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <ModalHeaderContainer>
          <FirstInfoContainer>
            <CheckColor checkin={checkin} checkout={checkout} />
            {checkin && <CardName>Check-in</CardName>}
            {checkout && <CardName>Check-out</CardName>}
            <ReserveDate>{handleType()}</ReserveDate>
            {(reservation?.cleaning_time ||
              reservation?.check_out_time ||
              reservation?.check_in_time) && <ReserveTime>às {handleTime()}</ReserveTime>}
          </FirstInfoContainer>
          {reservation?.warm_bed && <HotBed />}
        </ModalHeaderContainer>
        <ReservationInfoContainer>
          <div className="resume-info">
            {reservation?.ota !== 'Blocking' && (
              <>
                <p>{reservation?.adult_guest_quantity} adultos</p>
                <div className="dot" />

                <p>{reservation?.child_guest_quantity} crianças</p>
                <div className="dot" />

                <p>{reservation?.baby_guest_quantity} bebês</p>
                <div className="dot" />

                <p>{reservation?.has_pet} pets</p>
                <div className="dot" />
              </>
            )}
            <>
              <p>
                {calcDays()} {calcDays() > 1 ? 'diárias' : 'diária'}
              </p>
              {checkin && <div className="dot" />}
            </>
            {checkin && <p>{`saída ${formatedDateToShow(reservation?.check_out_date || '')}`}</p>}
          </div>
        </ReservationInfoContainer>
        <ContentContainer>
          <InfoContainer>
            <PropertyContainer>
              <PropertyTitle>{reservation?.property_code}</PropertyTitle>
              {checkin && (
                <AddressContainer onClick={() => handleSendAddressToClipBoard()}>
                  <PropertyAddress>{`${reservation?.address}, ${reservation?.city}`}</PropertyAddress>
                  <ShareAddress />
                </AddressContainer>
              )}
              {checkout && <PropertyAddress>{`${reservation?.address}, ${reservation?.city}`}</PropertyAddress>}
            </PropertyContainer>
          </InfoContainer>
          {checkin && (
            <InfoBottomContainer className={reservation?.ota === 'Blocking' ? 'blocking' : ''}>
              <LeftContainer onSubmit={formik.handleSubmit}>
                {reservation?.ota !== 'Blocking' && (
                  <ClientNameTitle>{reservation?.name}</ClientNameTitle>
                )}
                {reservation?.phone && reservation.ota !== 'Blocking' && (
                  <>
                    {!isEditPhoneNumber ? (
                      <ClientPhone
                        onClick={() =>
                          copyToClipboard(reservation?.phone || 'Número não informado')
                        }
                      >
                        <p>{formik.values.phoneNumber}</p>
                        <div>
                          <Tooltip text="Copiar celular">
                            <CopyContainer>
                              <Copy />
                            </CopyContainer>
                          </Tooltip>
                          <Tooltip text="Enviar mensagem">
                            <button type="button" onClick={() => handleMessageWhatsapp()}>
                              <img src={whatsapp} alt="" />
                            </button>
                          </Tooltip>
                          <Tooltip text="Editar telefone">
                            <PencilButton
                              type="button"
                              onClick={() => setIsEditPhoneNumber(!isEditPhoneNumber)}
                            >
                              <Edit />
                            </PencilButton>
                          </Tooltip>
                        </div>
                      </ClientPhone>
                    ) : (
                      <>
                        <TextFieldContainer>
                          <div>
                            <CountrySelector
                              country={'br'}
                              enableLongNumbers
                              autoFormat={false}
                              value={formik.values.phoneNumber}
                              onChange={(value) => formik.setFieldValue('phoneNumber', `+${value}`)}
                            />
                          </div>
                          <ClientPhone
                            onClick={() =>
                              copyToClipboard(reservation?.phone || 'Número não informado')
                            }
                          >
                            <Tooltip text="Salvar telefone">
                              <PencilButton type="submit">
                                <strong className="btn-saveCheckinPhone">Salvar</strong>
                              </PencilButton>
                            </Tooltip>
                          </ClientPhone>
                        </TextFieldContainer>
                      </>
                    )}
                  </>
                )}
              </LeftContainer>
            </InfoBottomContainer>
          )}
          {checkout && (
            <InfoBottomContainer className={reservation?.ota === 'Blocking' ? 'blocking' : ''}>
              <LeftContainer onSubmit={formik.handleSubmit}>
                <ClientNameTitle>
                  {reservation?.ota !== 'Blocking' ? reservation?.name : ''}
                </ClientNameTitle>
                <ClientPhone>
                  {reservation?.phone && reservation?.ota !== 'Blocking' && (
                    <>
                      {!isEditPhoneNumber ? (
                        <ClientPhone
                          onClick={() =>
                            copyToClipboard(reservation?.phone || 'Número não informado')
                          }
                        >
                          <p>{formik.values.phoneNumber}</p>
                          <div>
                            <Tooltip text="Copiar celular">
                              <CopyContainer>
                                <Copy />
                              </CopyContainer>
                            </Tooltip>
                            <Tooltip text="Enviar mensagem">
                              <button type="button" onClick={() => handleMessageWhatsapp()}>
                                <img src={whatsapp} alt="" />
                              </button>
                            </Tooltip>
                            <Tooltip text="Editar telefone">
                              <PencilButton
                                type="button"
                                onClick={() => setIsEditPhoneNumber(!isEditPhoneNumber)}
                              >
                                <Edit />
                              </PencilButton>
                            </Tooltip>
                          </div>
                        </ClientPhone>
                      ) : (
                        <>
                          <TextFieldContainer>
                            <div>
                              <CountrySelector
                                country={'br'}
                                enableLongNumbers
                                autoFormat={false}
                                value={formik.values.phoneNumber}
                                onChange={(value) =>
                                  formik.setFieldValue('phoneNumber', `+${value}`)
                                }
                              />
                            </div>
                            <ClientPhone
                              onClick={() =>
                                copyToClipboard(reservation?.phone || 'Número não informado')
                              }
                            >
                              <Tooltip text="Salvar telefone">
                                <PencilButton type="submit">
                                  <strong className="btn-saveCheckinPhone">Salvar</strong>
                                </PencilButton>
                              </Tooltip>
                            </ClientPhone>
                          </TextFieldContainer>
                        </>
                      )}
                    </>
                  )}
                </ClientPhone>
              </LeftContainer>
            </InfoBottomContainer>
          )}
        </ContentContainer>
        <BottomContainer>
          {checkin && (
            <>
              {reservation?.blocking_reason?.trim() === 'Owner use' && (
                <BlockingAlertContainer>
                  <p>
                    <strong>Observação: </strong>
                    será necessário montar a cama com roupas de cama
                  </p>
                </BlockingAlertContainer>
              )}
              <ButtonContainer>
                <ModalButton type="button" onClick={() => handleSendReservationCodeToClipboard()}>
                  <StyledIcon src={CopyBlueIcon} alt="copy-icon" />
                  <p>Código de reserva</p>
                </ModalButton>
                <ModalButton
                  type="button"
                  onClick={() => handleSendReservationDetailsToClipboard()}
                >
                  <StyledIcon src={CopyBlueIcon} alt="copy-icon" />
                  <p>Detalhes da reserva</p>
                </ModalButton>
                {reservation?.ota !== 'Blocking' && (
                  <ModalButton
                    onClick={() => handleEventSendInitialMessage('Checkin')}
                    type="button"
                  >
                    <WhatsApp />
                    <p>Mensagem pré-check-in (3 dias antes)</p>
                  </ModalButton>
                )}
                <ModalButton onClick={handleOpenFillInformationModal} type="button">
                  <AccessTime />
                  <p>Preencher informações (Horário entrada)</p>
                </ModalButton>
                {!reservation?.checklist?.concluded ? (
                  <>
                    {!reservation?.is_blocking && (
                      <ModalButton onClick={() => handleEventAlignChecking()} type="button">
                        <WhatsApp />
                        <p>Mensagem (Dia do check-in)</p>
                      </ModalButton>
                    )}
                    <ModalButton
                      onClick={() =>
                        handleRedirectToPage(
                          `https://app.pipefy.com/public/form/fg0DikP8/?franqueado=${fullName}&im_vel=${propertyCode}`,
                        )
                      }
                      type="button"
                    >
                      <StyledIcon src={FormsIcon} alt="Forms Icon" />
                      <p>Formulário de Check-in</p>
                    </ModalButton>
                    <ModalButton
                      isFull
                      onClick={() => handleConcludeChecklist(true)}
                      type="button"
                      disabled={!reservation?.was_contacted}
                    >
                      <Check />
                      <p>Concluir card</p>
                    </ModalButton>
                  </>
                ) : (
                  <>
                    <ModalButton onClick={() => handleEventAlignChecking()} type="button">
                      <WhatsApp />
                      <p>Reconfirmar informações</p>
                    </ModalButton>
                    <ModalButton onClick={() => handleEventAlignChecking()} type="button">
                      <StyledIcon src={FormsIcon} alt="Forms Icon" />
                      <p>Check-in concluído</p>
                    </ModalButton>
                    <ModalButton
                      isFull
                      type="button"
                      onClick={() => handleUndoConcludedChecklist()}
                    >
                      <p>Remover card dos concluídos</p>
                    </ModalButton>
                  </>
                )}
              </ButtonContainer>
            </>
          )}
          {checkout && (
            <>
              <ButtonContainer>
                <ModalButton type="button" onClick={() => handleSendReservationCodeToClipboard()}>
                  <StyledIcon src={CopyBlueIcon} alt="copy-icon" />
                  <p>Código de reserva</p>
                </ModalButton>
                <ModalButton
                  type="button"
                  onClick={() => handleSendReservationDetailsToClipboard()}
                >
                  <StyledIcon src={CopyBlueIcon} alt="copy-icon" />
                  <p>Detalhes da reserva</p>
                </ModalButton>
                {reservation?.ota !== 'Blocking' && (
                  <ModalButton
                    type="button"
                    onClick={() => handleEventSendInitialMessage('Checkout')}
                  >
                    <WhatsApp />
                    <p>Mensagem pré-check-out (1 dia antes)</p>
                  </ModalButton>
                )}
                <ModalButton type="button" onClick={handleOpenFillInformationModal}>
                  <AccessTime />
                  <p>Preencher informações (Horário saída)</p>
                </ModalButton>
                {!reservation?.checklist?.concluded ? (
                  <>
                    <ModalButton
                      type="button"
                      onClick={() =>
                        handleRedirectToPage(
                          `https://app.pipefy.com/public/form/QUcb2jty/?franqueado=${fullName}&im_vel=${propertyCode}&nome_do_h_spede=${guestName}&telefone_do_h_spede=${guestPhoneNumer}`,
                        )
                      }
                    >
                      <StyledIcon src={FormsIcon} alt="Forms Icon" />
                      <p>Formulário de Check-out</p>
                    </ModalButton>
                    {reservation?.ota !== 'Blocking' && (
                      <Tooltip text="Enviar formulário de satisfação para o hóspede">
                        <ModalButton
                          type="button"
                          onClick={() => handleRedirectToPage(feedBackWhatsappLink)}
                        >
                          <WhatsApp />
                          <p>Mensagem de feedback</p>
                        </ModalButton>
                      </Tooltip>
                    )}
                    <ModalButton isFull onClick={handleOpenCleaningInformationModal}>
                      <StyledIcon src={CleaningIcon} alt="Ícone de limpeza" />
                      <p>Dados sobre a limpeza</p>
                    </ModalButton>
                    {reservation?.ota !== 'Blocking' && (
                      <ModalButton
                        isFull
                        onClick={() => handleRedirectInSapron(`/danosdehospede/${reservationId}`)}
                      >
                        <StyledIcon src={FormsIcon} alt="Forms Icon" />
                        <p>Danos de hóspedes</p>
                      </ModalButton>
                    )}
                    <ModalButton
                      isFull
                      onClick={() => handleConcludeChecklist(true)}
                      type="button"
                      disabled={!reservation?.was_contacted || !wasChecked}
                    >
                      <Check />
                      <p>Concluir card</p>
                    </ModalButton>
                  </>
                ) : (
                  <>
                    <ModalButton type="button">
                      <StyledIcon src={FormsIcon} alt="Forms Icon" />
                      <p>Check-out realizado</p>
                    </ModalButton>
                    {reservation?.ota !== 'Blocking' && (
                      <Tooltip text="Enviar formulário de satisfação para o hóspede">
                        <ModalButton onClick={() => handleRedirectToPage(feedBackWhatsappLink)}>
                          <WhatsApp />
                          <p>Mensagem de feedback</p>
                        </ModalButton>
                      </Tooltip>
                    )}
                    <ModalButton isFull onClick={handleOpenCleaningInformationModal}>
                      <StyledIcon src={CleaningIcon} alt="Ícone de limpeza" />
                      <p>Dados sobre a limpeza</p>
                    </ModalButton>
                    {reservation?.ota !== 'Blocking' && (
                      <ModalButton
                        isFull
                        onClick={() => handleRedirectInSapron(`/danosdehospede/${reservationId}`)}
                      >
                        <StyledIcon src={FormsIcon} alt="Forms Icon" />
                        <p>Danos de hóspedes</p>
                      </ModalButton>
                    )}
                    <ModalButton
                      isFull
                      type="button"
                      onClick={() => handleUndoConcludedChecklist()}
                    >
                      <p>Remover card dos concluídos</p>
                    </ModalButton>
                  </>
                )}
              </ButtonContainer>
            </>
          )}
        </BottomContainer>
        {lateralModal && (
          <LateralModal
            setReservation={setReservation}
            anchor={mobile ? 'left' : 'right'}
            reservation={reservation}
            hot={hot}
            recive={recive}
            open={lateralModal}
            onClose={onCloseLateralModal}
            localData={localData}
            updateLocalData={updateLocalData}
            checkin={checkin}
            checkout={checkout && !isCleaningModal}
            clearning={checkout && isCleaningModal}
            statusContact={statusContact}
            setStatusContact={setStatusContact}
          />
        )}
      </ModalContainer>
      <ModalBackdrop onClick={onClose} />
    </>
  );
};

Modal.defaultProps = {
  handlePrevent: handleStopEvent,
  onClose: () => {},
  checkin: false,
  checkout: false,
  hot: false,
  recive: false,
  localData: [],
};

export default Modal;
