import styled from 'styled-components';

import { TEDLISTPAGEMARGIN } from './utils';

export const Container = styled.div`
  border-radius: 10px;
  margin: ${TEDLISTPAGEMARGIN / 2}px;
  width: calc(100% - ${TEDLISTPAGEMARGIN}px);
  height: calc(100% - ${TEDLISTPAGEMARGIN}px);

  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow: hidden;

  @media (max-width: 900px) {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  background-color: #fff;
  padding: 16px 25px;
`;

export const ContainerButton = styled.div`
  input[type="file"] {
    display: none;
  }

  label {
    border-radius: 10px;
    min-width: fit-content;
    padding: 10px 20px;
    cursor: pointer;
    background: ${({ theme }) => theme.palette.blue._400.hex()};
    transition: all 0.5s ease;
    border: 2px solid transparent;
    font-size: 14px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.white.main.hex()};

    &:hover {
      border: 2px solid ${({ theme }) => theme.palette.blue._930.hex()};
      background: ${({ theme }) => theme.palette.blue._930.hex()};
    }
  }

  button {
    padding: 10px 20px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    font-family: ${({ theme }) => theme.fonts.familys._6} !important;

    &:hover {
      border: 2px solid ${({ theme }) => theme.palette.blue._930.hex()} !important;
      background: ${({ theme }) => theme.palette.blue._930.hex()} !important;
      transition: all 0s ease !important;
    }

    &.outlined {
      &:hover {
        background: transparent !important;
      }
    }
  }
`;

export const ContainerUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  span {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.blue._400.hex()};
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ContentUpload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
  }
  svg {
    margin: 8px 0px 0 8px;
    cursor: pointer;
  }
`;

export const ContainerDeleteFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
