import { useContextSelector } from 'use-context-selector';
import { OnboardingHandoverContext } from '../../context/OnboardingContext/OnboardingHandoverContext';

export function useOnboardingHandover() {
  const dealID = useContextSelector(OnboardingHandoverContext, (state) => state.dealID);
  const setDealID = useContextSelector(OnboardingHandoverContext, (state) => state.setDealID);
  const pageVisible = useContextSelector(OnboardingHandoverContext, (state) => state.pageVisible);
  const setPageVisible = useContextSelector(OnboardingHandoverContext, (state) => state.setPageVisible);
  const infosPipeDrive = useContextSelector(OnboardingHandoverContext, (state) => state.infosPipeDrive);
  const setInfosPipeDrive = useContextSelector(OnboardingHandoverContext, (state) => state.setInfosPipeDrive);
  const owner = useContextSelector(OnboardingHandoverContext, (state) => state.owner);
  const setOwner = useContextSelector(OnboardingHandoverContext, (state) => state.setOwner);
  const resetFormik = useContextSelector(OnboardingHandoverContext, (state) => state.resetFormik);
  const setResetFormik = useContextSelector(OnboardingHandoverContext, (state) => state.setResetFormik);
  const visibility = useContextSelector(OnboardingHandoverContext, (state) => state.visibility);
  const setVisibility = useContextSelector(OnboardingHandoverContext, (state) => state.setVisibility);
  const resetVisibility = useContextSelector(OnboardingHandoverContext, (state) => state.resetVisibility);
  const totalErrors = useContextSelector(OnboardingHandoverContext, (state) => state.totalErrors);
  const setTotalErrors = useContextSelector(OnboardingHandoverContext, (state) => state.setTotalErrors);
  const loadingOwner = useContextSelector(OnboardingHandoverContext, (state) => state.loadingOwner);
  const setLoadingOwner = useContextSelector(OnboardingHandoverContext, (state) => state.setLoadingOwner);
  const listOwner = useContextSelector(OnboardingHandoverContext, (state) => state.listOwner);
  const setListOwner = useContextSelector(OnboardingHandoverContext, (state) => state.setListOwner);
  const listOwnerFiltered = useContextSelector(OnboardingHandoverContext, (state) => state.listOwnerFiltered);
  const setListOwnerFiltered = useContextSelector(OnboardingHandoverContext, (state) => state.setListOwnerFiltered);
  const listHost = useContextSelector(OnboardingHandoverContext, (state) => state.listHost);
  const setListHost = useContextSelector(OnboardingHandoverContext, (state) => state.setListHost);
  const listPartner = useContextSelector(OnboardingHandoverContext, (state) => state.listPartner);
  const setListPartner = useContextSelector(OnboardingHandoverContext, (state) => state.setListPartner);
  const openModalAddProperty = useContextSelector(OnboardingHandoverContext, (state) => state.openModalAddProperty);
  const setOpenModalAddProperty = useContextSelector(OnboardingHandoverContext, (state) => state.setOpenModalAddProperty);
  const orderGridBy = useContextSelector(OnboardingHandoverContext, (state) => state.orderGridBy);
  const setOrderGridBy = useContextSelector(OnboardingHandoverContext, (state) => state.setOrderGridBy);

  return {
    dealID,
    setDealID,
    pageVisible,
    setPageVisible,
    infosPipeDrive,
    setInfosPipeDrive,
    owner,
    setOwner,
    resetFormik,
    setResetFormik,
    visibility,
    setVisibility,
    resetVisibility,
    totalErrors,
    setTotalErrors,
    loadingOwner,
    setLoadingOwner,
    listOwner,
    setListOwner,
    listOwnerFiltered,
    setListOwnerFiltered,
    listHost,
    setListHost,
    listPartner,
    setListPartner,
    openModalAddProperty,
    setOpenModalAddProperty,
    orderGridBy,
    setOrderGridBy
  };
}
