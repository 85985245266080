/* eslint-disable react/require-default-props */
import { useState, useEffect } from 'react';
import {
  FormControlLabel,
  styled,
  Switch,
  SwitchProps,
} from '@mui/material';

import { UseFormik } from '../../../../../utils/Formik/types';

import {
  Container,
  SwitchButton,
} from './styles';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  transition: 'all 0.3s',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '300ms',
    color: '#555',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#194BDD',
      '& + .MuiSwitch-track': {
        backgroundColor:
          theme.palette.mode === 'dark' ? '#D1D1D1' : '#D1D1D1',
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],

    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 18 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#D1D1D1' : '#D1D1D1',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface Props {
  id: string,
  formik: UseFormik<any>;
  value?: boolean;
}

const OnboardingSwitch = ({
  id,
  formik,
  value,
}: Props) : JSX.Element => {
  const [checked, setChecked] = useState(value || false);

  function onChange() {
    setChecked(!checked);

    if (formik) {
      formik.setFieldValue(id, !checked);
      formik.setFieldTouched(
        id, true, false,
      );
    }
  }

  useEffect(() => {
    setChecked(formik?.values?.[id] || false);
  }, [formik?.values?.[id]]);

  return (
    <Container>
      <SwitchButton>
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} />}
          value
          checked={checked}
          onChange={() => onChange()}
          label={false}
        />
      </SwitchButton>
    </Container>
  );
};

export default OnboardingSwitch;
