import React, { ReactNode, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress } from '@mui/material';

import {
  Container, StyledTextField, ListOptions, LabelContainer, Label, StarSymbol,
} from './styles';

import { UseFormik } from '../../utils/Formik/types';
import { ErrorIcon } from '../TextField/styles';
import { Type } from '../HelperText/types';
import HelperText from '../HelperText/HelperText';

export interface SelectProps {
  optionValue?: string;
  optionText?: string;
  addNewOption?: boolean;
  noOptions?: boolean;
  cpf?: string;
  cnpj?: string;
}

interface AutoCompleteProps {
  label?: string;
  id: string;
  dataCy?: string;
  placeholder?: string;
  required?: boolean;
  inputTag?: string;
  addOption?: string;
  noOptionText?: string;
  isNormalWeight?: boolean;
  options?: SelectProps[];
  formik?: UseFormik<any>;
  startAdornment?: ReactNode;
  asyncOptions?: (value: string) => Promise<SelectProps[]>,
  setNewValue?: (value: boolean) => void,
  helperText?: string,
  onChange?: (a: string) => void,
  labelClassName?: string;
  disableRequireSymbol?: boolean; 
  disableHelperText?: boolean; 
  error?: boolean; 
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
  label,
  id,
  dataCy = '',
  placeholder,
  required,
  inputTag = 'NOVO',
  addOption,
  noOptionText = 'Sem opção disponível',
  options = [],
  formik,
  startAdornment,
  asyncOptions,
  setNewValue,
  helperText,
  isNormalWeight = false,
  onChange,
  labelClassName = 'labelClassName',
  disableRequireSymbol = false,
  disableHelperText = false,
  error = false,
}) => {
  const [value, setValue] = useState<SelectProps | null>(null);
  const [open, setOpen] = useState(false);
  const [newValueOption, setNewValueOption] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [requestOptions, setRequestOptions] = useState<SelectProps[]>([]);

  useEffect(() => {
    if (setNewValue) {
      setNewValue(newValueOption);
    }
  }, [newValueOption]);

  const isHelperTextError = formik?.touched[id] && formik?.errors[id] !== '' && formik?.errors[id];

  const helperTextValue = () => {
    if (!disableHelperText && isHelperTextError) {
      return (
        <>
          <ErrorIcon />
          {formik?.errors[id]}

        </>
      );
    }

    return helperText;
  };

  const helperTextType = isHelperTextError ? Type.error : Type.primary;

  return (
    <Container isNormalWeight={isNormalWeight}>
      {required ? (
        <LabelContainer>
          {!disableRequireSymbol && <StarSymbol>*</StarSymbol>}
          <Label className={labelClassName}>{label}</Label>
        </LabelContainer>
      ) : (
        <label className={labelClassName} htmlFor={id}>{label}</label>
      )}
      <Autocomplete
        id={id}
        data-cy={dataCy}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={async (_event, newValue) => {
          const valueOption = typeof newValue === 'string' ? newValue : newValue?.optionValue;
          const valueNewOption = typeof newValue === 'string' ? false : newValue?.addNewOption;

          setValue({ optionText: valueOption });
          setNewValueOption(!!valueNewOption);
          if (formik) {
            formik?.setFieldValue(id, valueOption);
            formik?.setFieldTouched(
              id, true, false,
            );
          }
          if (onChange) {
            onChange(valueOption || '');
          }
        }}
        options={asyncOptions ? requestOptions : options}
        loading={loading}
        filterOptions={(filterOptions: SelectProps[], params) => {
          const filtered = filterOptions
            .filter((opt) => opt.optionText?.toLowerCase()
              .includes(params.inputValue.toLowerCase())
              || opt.cpf?.toLowerCase().includes(params.inputValue.toLowerCase())
              || opt.cnpj?.toLowerCase().includes(params.inputValue.toLowerCase()))
            .splice(0, 20);
          const { inputValue } = params;

          if (inputValue === '') {
            return [];
          }

          if (addOption) {
            filtered.push({
              optionValue: inputValue,
              optionText: inputValue,
              addNewOption: true,
              noOptions: filtered.length < 1,
            });
          }

          return filtered;
        }}
        renderOption={(props, option) => {
          if (option.addNewOption) {
            return (
              <ListOptions {...props}>
                <div className="containerDiv">
                  {(option.noOptions
                  && (
                  <div className="noOptions" key={noOptionText}>
                    {noOptionText}
                  </div>
                  )
                  )}
                  {addOption && <div className="addOption" key={addOption}>{addOption}</div>}
                </div>
              </ListOptions>
            );
          }
          return <ListOptions {...props}>{option.optionText}</ListOptions>;
        }}
        getOptionLabel={(option: any) => (typeof option?.optionText === 'string' ? option?.optionText : '')}
        isOptionEqualToValue={(option) => option.optionText === value?.optionText}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            required={required}
            error={error}
            onFocus={() => {
              formik?.setFieldTouched(
                id, true, false,
              );
            }}
            onChange={async (e) => {
              formik?.setFieldTouched(
                id, true, false,
              );
              if (asyncOptions) {
                setLoading(true);
                setRequestOptions(await asyncOptions(e.target.value));
                setLoading(false);
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                  {newValueOption ? <div className="tag"><span>{inputTag}</span></div> : null}
                </>
              ),
              startAdornment,
            }}
          />
        )}
      />
      { !!helperTextValue() && (
        <HelperText type={helperTextType}>{helperTextValue()}</HelperText>
      )}
    </Container>
  );
};

export default AutoComplete;
