import {
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';

import { createContext } from 'use-context-selector';

import { AccountOwnerOnboardingProps, PipedriveOwnerInfo } from '../../services/Onboarding/types';
import { IAccountSimpleHostsResponse } from '../../services/AccountSimpleHosts/types';
import { RequestPartner } from '../../services/Partner/types';
import { SectionsVisibilityRules, SectionsVisibilityProps, SectionsOptions, PagesOptions, OrderGridOptions } from './types';
import { initialValuesTotalErrors, initialValuesVisibility, sortAllOwnersList, sortPartialOwnersList } from './utils';

type ModalAddPropertyProps = {
  open: boolean,
  variant: '1' | '2',
};

interface OnboardingHandoverContextData {
  dealID: string,
  setDealID: (deal: string) => void,
  pageVisible: PagesOptions,
  setPageVisible: (page: PagesOptions) => void,
  infosPipeDrive: PipedriveOwnerInfo,
  setInfosPipeDrive: (infos: PipedriveOwnerInfo) => void,
  owner: AccountOwnerOnboardingProps,
  setOwner: (owner: AccountOwnerOnboardingProps) => void,
  resetFormik: boolean,
  setResetFormik: (reset: boolean) => void,
  visibility: SectionsVisibilityProps,
  setVisibility: (sectionVisible: SectionsVisibilityRules) => void,
  resetVisibility: () => void,
  totalErrors: Record<SectionsOptions, number>,
  setTotalErrors: (sections: Record<SectionsOptions, number>) => void,
  loadingOwner: boolean,
  setLoadingOwner: (loading: boolean) => void,
  listOwner: Array<AccountOwnerOnboardingProps>,
  setListOwner: (list: Array<AccountOwnerOnboardingProps>) => void,
  listOwnerFiltered: Array<AccountOwnerOnboardingProps>,
  setListOwnerFiltered: (list: Array<AccountOwnerOnboardingProps>) => void,
  listHost: Array<IAccountSimpleHostsResponse>,
  setListHost: (list: Array<IAccountSimpleHostsResponse>) => void,
  listPartner: Array<RequestPartner>,
  setListPartner: (list: Array<RequestPartner>) => void,
  openModalAddProperty: ModalAddPropertyProps,
  setOpenModalAddProperty: (modal: ModalAddPropertyProps) => void,
  orderGridBy: OrderGridOptions,
  setOrderGridBy: Function,
}

interface Props {
  children: ReactNode;
}

export const OnboardingHandoverContext = createContext<OnboardingHandoverContextData>({
  dealID: '',
  setDealID: (deal: string) => deal,
  pageVisible: 'Page_Initial',
  setPageVisible: (page: PagesOptions) => page,
  infosPipeDrive: {} as PipedriveOwnerInfo,
  setInfosPipeDrive: (infos: PipedriveOwnerInfo) => infos,
  owner: {} as AccountOwnerOnboardingProps,
  setOwner: (owner: AccountOwnerOnboardingProps) => owner,
  resetFormik: false,
  setResetFormik: (reset: boolean) => reset,
  visibility: initialValuesVisibility,
  setVisibility: (visibility: SectionsVisibilityRules) => visibility,
  resetVisibility: () => {},
  totalErrors: initialValuesTotalErrors,
  setTotalErrors: (sections: Record<SectionsOptions, number>) => sections,
  loadingOwner: false,
  setLoadingOwner: (loading: boolean) => loading,
  listOwner: [],
  setListOwner: (list: Array<AccountOwnerOnboardingProps>) => list,
  listOwnerFiltered: [],
  setListOwnerFiltered: (list: Array<AccountOwnerOnboardingProps>) => list,
  listHost: [],
  setListHost: (list: Array<IAccountSimpleHostsResponse>) => list,
  listPartner: [],
  setListPartner: (list: Array<RequestPartner>) => list,
  openModalAddProperty: {
    open: false,
    variant: '1',
  },
  setOpenModalAddProperty: (modal: ModalAddPropertyProps) => modal,
  orderGridBy: {
    order_by: 'name',
    order_option: 'all',
    order: 'asc',
    order_start_position: 0,
    order_end_position: 0,
  },
  setOrderGridBy: () => {},
} as OnboardingHandoverContextData);

export const OnboardingHandoverProvider = ({
  children,
}: Props) => {
  const [dealID, setDealID] = useState<string>('');
  const [pageVisible, setPageVisible] = useState<PagesOptions>('Page_Initial');
  const [infosPipeDrive, setInfosPipeDrive] = useState<PipedriveOwnerInfo>({} as PipedriveOwnerInfo);
  const [owner, setOwner] = useState<AccountOwnerOnboardingProps>({} as AccountOwnerOnboardingProps);
  const [resetFormik, setResetFormik] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<SectionsVisibilityProps>(initialValuesVisibility);
  const [totalErrors, setTotalErrors] = useState<Record<SectionsOptions, number>>(initialValuesTotalErrors);  
  const [loadingOwner, setLoadingOwner] = useState<boolean>(false);
  const [listOwner, setListOwner] = useState<Array<AccountOwnerOnboardingProps>>([]);
  const [listOwnerFiltered, setListOwnerFiltered] = useState<Array<AccountOwnerOnboardingProps>>([]);
  const [listHost, setListHost] = useState<Array<IAccountSimpleHostsResponse>>([]);
  const [listPartner, setListPartner] = useState<Array<RequestPartner>>([]);
  const [openModalAddProperty, setOpenModalAddProperty] = useState<ModalAddPropertyProps>({
    open: false,
    variant: '1',
  });
  const [orderGridBy, setOrderGridBy] = useState<OrderGridOptions>({
    order_by: 'name',
    order_option: 'all',
    order: 'asc',
    order_start_position: 0,
    order_end_position: 0,
  });

  const handleChangeVisibility = useCallback((sectionVisible: SectionsVisibilityRules) => {
    setVisibility((state) => {
      const isCurrentlyVisible = state[sectionVisible];
      const newVisibility = Object.keys(state).reduce((acc, key) => {
        acc[key as SectionsVisibilityRules] = key === sectionVisible ? !isCurrentlyVisible : false;
        return acc;
      }, {} as SectionsVisibilityProps);
  
      return newVisibility;
    });
  }, []);
  
  const handleResetVisibility = useCallback(() => {
    setVisibility(initialValuesVisibility);
  }, []);

  const handleSetOrderGridBy = useCallback((orderBy: OrderGridOptions) => {
    setOrderGridBy(orderBy);
  }, []);

  useEffect(() => {
    const response: AccountOwnerOnboardingProps[] = orderGridBy.order_option === 'all'
      ? sortAllOwnersList(
        listOwnerFiltered,
        orderGridBy.order_by,
        orderGridBy.order,
      )
      : sortPartialOwnersList(
        listOwnerFiltered,
        orderGridBy.order_by,
        orderGridBy.order,
        orderGridBy?.order_start_position || 0,
        orderGridBy?.order_end_position || 0,
      );

    setListOwnerFiltered([...response]);
  }, [orderGridBy]);

  const value = useMemo(() => ({
    dealID,
    setDealID,
    pageVisible,
    setPageVisible,
    infosPipeDrive,
    setInfosPipeDrive,
    owner,
    setOwner,
    resetFormik,
    setResetFormik,
    visibility,
    setVisibility: handleChangeVisibility,
    resetVisibility: handleResetVisibility,
    totalErrors,
    setTotalErrors,
    loadingOwner,
    setLoadingOwner,
    listOwner,
    setListOwner,
    listOwnerFiltered,
    setListOwnerFiltered,
    listHost,
    setListHost,
    listPartner,
    setListPartner,
    openModalAddProperty,
    setOpenModalAddProperty,
    orderGridBy,
    setOrderGridBy: handleSetOrderGridBy,
  }), [
    dealID,
    pageVisible,
    infosPipeDrive,
    owner,
    resetFormik,
    visibility,
    totalErrors,
    loadingOwner,
    listOwner,
    listOwnerFiltered,
    listHost,
    listPartner,
    openModalAddProperty,
    orderGridBy,
  ]);

  return (
    <OnboardingHandoverContext.Provider
      value={value}
    >
      {children}
    </OnboardingHandoverContext.Provider>
  );
}
