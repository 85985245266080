import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.white._210.hex()};

  :not(.ignore-margin) {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
  }

  @media (max-width: 640px) {
    :not(.ignore-padding) {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }
  }
`;
