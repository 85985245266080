import styled from 'styled-components';
import { PagesOptions } from '../../context/OnboardingContext/types';

export const Wrapper = styled.div`
 width: 100%;
 height: 100%;
 overflow: hidden;
`;

export const Container = styled.div<{pageVisible: PagesOptions}>`
  width: 100%;
  height: ${({ pageVisible }) => ['Page_Initial' as PagesOptions].includes(pageVisible) ? '100%' : 'calc(100% - 100px)'};
  background-color: ${({ theme }) => theme.palette.white.main.hex()};

  overflow-y: auto;
  overflow-x: hidden;
 
  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.white.main.hex()};
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.grey._320.hex()};
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  background: ${({theme}) => theme.palette.white.main.hex()};
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0px 2px 4px 0px #00000040;
`;

export const Title = styled.h2`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
`;
