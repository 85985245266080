import 'styled-components';
import color from 'color';

const theme = {
  fonts: {
    familys: {
      _1: 'Quicksand',
      _2: 'sans-serif',
      _3: 'Arial',
      _4: 'Helvetica',
      _5: 'Open Sans',
      _6: 'Inter',
      _7: 'Plus Jakarta Sans',
    },
    sizes: {
      tiny: 0.8,
      smaller: 1,
      small: 1.2,
      regular: 1.4,
      medium: 1.6,
      larger: 1.8,
      xlarger: 2.0,
      big: 2.8,
      bigger: 3.6,
      huge: 5.2,
    },
    weights: {
      normal: 400,
      regular: 500,
      bold: 700,
    },
  },
  spacing: {
    small: '10px',
    medium: '20px',
    large: '30px',
    form: '20px',
  },
  palette: {
    white: {
      main: color('#FFFFFF'),
      _80: color('#FFEFD7'),
      _100: color('#F1FFED'),
      _200: color('#F6F6F6'),
      _210: color('#F9F9F9'),
      _230: color('#E1E7ED'),
      _250: color('#E5E5E5'),
      _300: color('#EBEBF5'),
      _330: color('#ECECEC'),
      _350: color('#EBF0F5'),
      _400: color('#F3F6F9'),
      _430: color('#DFDFDF'),
      _600: color('#d6dee1'),
      _650: color('#d9dcdf'),
      _700: color('#DBDDE8'),
      _750: color('#E0E2EF'),
      _900: color('#9AA5B0'),
    },

    black: {
      main: color('#000000'),
      _100: color('#151B26'),
    },

    green: {
      main: color('#41B592'),
      _50: color('#E2FFF6'),
      _100: color('#D9F0E9'),
      _120: color('#BFEBDD'),
      _150: color('#AAE0AC'),
      _200: color('#67E4D5'),
      _250: color('#54DBB2'),
      _300: color('#03C289'),
      _700: color('#219653'),
    },

    orange: {
      main: color('#DD4530'),
      _100: color('#F7E1E1'),
      _200: color('#E7B3B3'),
      _300: color('#EB7980'),
      _350: color('#F9424E'),
      _400: color('#FB5F5B'),
      _450: color('#DE002B'),
      _600: color('#EB6B57'),
      _700: color('#E35B4C'),
    },

    yellow: {
      main: color('#EFB776'),
      _300: color('#FFF3E0'),
      _400: color('#FFCD80'),
      _500: color('#FFD12E'),
      _800: color('#F5A623'),
      _900: color('#FF9900'),
    },

    blue: {
      main: color('#A3DDFE'),
      _20: color('#BCE3FF'),
      _30: color('#D8E3FF'),
      _50: color('#F3F6F9'),
      _90: color('#C2C8F1'),
      _100: color('#3CC3EE'),
      _150: color('#1D9BE1'),
      _200: color('#3F50E5'),
      _250: color('#394760'),
      _400: color('#0D4BD0'),
      _700: color('#51626F'),
      _800: color('#215789'),
      _850: color('#0D4BD0'),
      _900: color('#283143'),
      _910: color('#042675'),
      _920: color('#2043CF'),
      _930: color('#11226D'),
      _950: color('#151B26'),
      _960: color('#1B212C'),
      _970: color('#001840'),
    },

    grey: {
      _50: color('#e3e7eb'),
      _100: color('#FAFAFA'),
      _150: color('#F6F7F9'),
      _200: color('#F5F5F5'),
      _230: color('#FCFCFC'),
      _240: color('#EFF1F5'),
      _250: color('#f7f7f7'),
      _260: color('#F2F3F9'),
      _280: color('#E2E2E2'),
      _300: color('#EEEEEE'),
      _305: color('#BABABA'),
      _310: color('#B6C5D7'),
      _315: color('#C0CDDD'),
      _320: color('#C4C4C4'),
      _325: color('#ACACAC'),
      _330: color('#CFD9E5'),
      _335: color('#BDBDBD'),
      _340: color('#E7EAF1'),
      _345: color('#C1C1C1'),
      _350: color('#DADADA'),
      _360: color('#D0D0D0'),
      _365: color('#B5C3D8'),
      _370: color('#B5B5B5'),
      _375: color('#B5BAC8'),
      _380: color('#C0C0C0'),
      _385: color('#C9C9c9'),
      _390: color('#D8DADD'),
      _395: color('#E9E9E9'),
      _400: color('#D9DCDF'),
      _410: color('#D1D1D1'),
      _420: color('#D2D5D8'),
      _425: color('#D9D9D9'),
      _430: color('#CECECE'),
      _435: color('#C8D0DC'),
      _440: color('#D6DDEB'),
      _445: color('#909AAC'),
      _450: color('#949BA0'),
      _455: color('#6C6C6C'),
      _460: color('#B7B7B7'),
      _465: color('#69707E'),
      _470: color('#3C3C3C'),
      _550: color('#949BA0'),
      _560: color('#969696'),
      _580: color('#40495A'),
      _600: color('#757575'),
      _610: color('#B2BBC3'),
      _650: color('#C6C6C6'),
      _660: color('#BEBEBE'),
      _670: color('#C8C8C8'),
      _680: color('#606670'),
      _700: color('#62656E'),
      _720: color('#62656F'),
      _750: color('#767676'),
      _760: color('#888E9B'),
      _765: color('#848b96'),
      _800: color('#959595'),
      _810: color('#9E9E9E'),
      _900: color('#212121'),
    },

    red: {
      _50: color('#FFCECE'),
      _100: color('#EFA99F'),
      _200: color('#E47878'),
      _250: color('#EB6B56'),
      _300: color('#EB6B57'),
      _330: color('#FF2732'),
      _350: color('#E96956'),
      _400: color('#EB6957'),
      _450: color('#F0655A'),
      _500: color('#CF4F44'),
      _550: color('#E35B4C'),
      _600: color('#FF2732'),
      _650: color('#CC2D2D'),
    },

    pink: {
      main: color('#DA2B7F'),
    },
  },
} as const;

type Theme = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}

export default theme;
