import axios from 'axios';
import React from 'react';

import { useQuery } from 'react-query';
import { useToast } from '../../context/ToastContext';
import { ICity, IState, ICountry } from './types';

interface IEnable {
  country: boolean;
}

interface IUseIBGE {
  uf?: string;
  enable?: IEnable;
}

export type StatesProps = {
  data: IState[],
  stateMappings: Record<string, string[]>,
  loading: boolean,
};

export const useIBGE = ({ uf, enable }: IUseIBGE) => {
  const toast = useToast();

  const [citys, setCitys] = React.useState<ICity[]>();
  const [isLoading, setIsLoading] = React.useState<boolean | undefined>(undefined);

  const { data: states, isFetched: statesLoading } = useQuery(
    'IBGELocationData-states',
    async (): Promise<IState[]> => {
      const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
      const formatData: IState[] = response.data;
      return formatData;
    },
    {
      retry: false,
      retryOnMount: false,
      refetchOnMount: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const { data: countrys, isFetched: countryLoading } = useQuery(
    'IBGELocationData-countrys',
    async (): Promise<ICountry[]> => {
      const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/paises/paises');
      const formatData: ICountry[] = response.data;
      return formatData;
    },
    {
      retry: false,
      enabled: enable?.country || false,
      retryOnMount: false,
      refetchOnMount: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: (data) => {
        const filteredData = data?.filter((
          item, index, array,
        ) => index === array.findIndex((t) => t.nome.abreviado === item.nome.abreviado));
        return filteredData;
      },
    },
  );

  React.useEffect(() => {
    if (uf && states) {
      const handleGetCity = async () => {
        try {
          setIsLoading(true);

          const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);
          const data = await response.json();

          setCitys(data);
          setIsLoading(false);
        } catch (error) {
          toast.error('Erro ao buscar cidades');
        }
      };
      handleGetCity();
    }
  }, [uf]);

  const getStateMappings = () => {
    if (!states) return {};

    const mappings: Record<string, string[]> = {};
    states.forEach((state) => {
      const stateName = `${state.nome}`.toLowerCase();
      const statesSigla = `${state.sigla}`.toLowerCase();
      mappings[stateName] = [stateName, statesSigla];
      mappings[statesSigla] = [stateName, statesSigla];
    });
    return mappings;
  };

  return {
    citys: {
      data: citys,
      loading: isLoading,
    },

    states: {
      data: states || [],
      stateMappings: getStateMappings(),
      loading: statesLoading,
    } as StatesProps,

    country: {
      data: countrys,
      loading: countryLoading,
    },
  };
};

useIBGE.defaultProps = {
  result: {
    cityes: undefined,
    states: undefined,
  },
  enable: undefined,
};
