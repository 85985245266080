import React, { Fragment } from 'react';
import { PhoneInputProps } from 'react-phone-input-2';
import { ContainerLabel, CountrySelectorStyled } from './style';
import { ErrorIcon } from '../TextField/styles';
import HelperText from '../HelperText';
import { Type } from '../HelperText/types';

interface ICountrySelector extends PhoneInputProps {
  label?: string;
  overWrite?: string;
  id?: any;
  formik?: any;
  labelClassName?: string,
  disableHelperText?: boolean;
  error?: boolean,
}

const CountrySelector = ({ id, formik, label, overWrite, labelClassName, disableHelperText = false, error = false, ...rest }: ICountrySelector) => {
  const isHelperTextError = formik?.touched[id] && formik?.errors[id] !== '' && formik?.errors[id];

  const helperTextValue = () => {
    if (!disableHelperText && isHelperTextError) {
      return (
        <>
          <ErrorIcon />
          {formik?.errors[id]}
        </>
      );
    }
    return '';
  };

  const helperTextType = isHelperTextError ? Type.error : Type.primary;

  return (
    <>
      {label && (
        <ContainerLabel>
          <p className={labelClassName}>{label}</p>
        </ContainerLabel>
      )}

      {overWrite && (
        <div className={overWrite}>
          <CountrySelectorStyled error={error} {...rest} />
        </div>
      )}

      {!overWrite && <CountrySelectorStyled error={error} {...rest} />}

      {!!helperTextValue() && <HelperText type={helperTextType}>{helperTextValue()}</HelperText>}
    </>
  );
};

CountrySelector.defaultProps = {
  label: undefined,
  overWrite: undefined,
  id: undefined,
  formik: undefined,
  labelClassName: '',
  disableHelperText: false,
  error: false,
};

export default CountrySelector;
