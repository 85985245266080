import React, { useMemo } from 'react';
import SimpleSelect, { ISelectProps, SelectOption } from './SimpleSelect';
import { compareList } from '../../utils/Sorting';
import { states } from '../../services/Address/states';

type Props = Omit<ISelectProps, 'options' | 'label'> & {
  label?: string;
  labelClassName?: string;
};

const StateSelect: React.FC<Props> = ({
  id,
  label = 'UF',
  placeholder = 'Selecione',
  disabled = false,
  formik,
  required,
  disableRequireSymbol = false,
  disableHelperText = false,
  labelClassName,
  error = false,
}) => {
  const isStateAbbreviation = (state: string) => states.some(({ acronym }) => `${acronym}`.toLowerCase() === state.toLowerCase());

  const selectOptions = useMemo(() => {
    const allOptions = states.map<SelectOption>(({ name, acronym }) => ({
      valueLabel: `${acronym}`.toUpperCase(),
      value: name,
    }));

    const state = `${formik?.values?.[id]}`;
    if (isStateAbbreviation(state)) {
      return states.map<SelectOption>(({ acronym }) => ({
        valueLabel: `${acronym}`.toUpperCase(),
        value: `${acronym}`.toUpperCase(),
      }));
    }

    return allOptions;
  }, [states, formik?.values?.[id]]);

  return (
    <SimpleSelect
      label={label}
      id={id}
      placeholder={placeholder}
      options={selectOptions.sort((a, b) => compareList(`${a.value}`, `${b.value}`))}
      formik={formik}
      disabled={disabled}
      required={required}
      disableRequireSymbol={disableRequireSymbol}
      disableHelperText={disableHelperText}
      labelClassName={labelClassName}
      error={error}
    />
  );
};

export default StateSelect;
