import styled from 'styled-components';

export const Container = styled.div`
  .labelClassName {
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 16.94px !important;
    text-align: left !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  }
`;
