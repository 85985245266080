import styled, { css } from 'styled-components';
import { TextField } from '@mui/material';

export const Container = styled.div<{ isNormalWeight: boolean }>`
  && {
    display: flex;
    flex: 1;
    flex-direction: column;

    label {
      color: ${({ theme }) => theme.palette.blue._950.hex()};
      font-family: ${({ isNormalWeight }) => (isNormalWeight ? 'Arial' : 'Quicksand')}, sans-serif;
      font-weight: ${({ isNormalWeight }) => (isNormalWeight ? '400' : '700')};
      font-size: 14px;
      padding-bottom: 8px;
    }
  }
`;

export const ListOptions = styled.li`
  && {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 14px;

    .containerDiv {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .addOption {
      border-top: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
      width: 100%;
      padding-top: 15px;
      background: ${({ theme }) => `linear-gradient(180deg, ${theme.palette.red._500.hex()} 0%, ${theme.palette.orange._600.hex()} 100%)`};
      color: transparent;
      -webkit-background-clip: text;
    }

    .noOptions {
      color: ${({ theme }) => theme.palette.blue._950.hex()};
      padding-bottom: 15px;
    }
  }
`;

export const StyledTextField = styled(TextField)<{
  error?: boolean;
}>`
  && {
    .MuiOutlinedInput-root {
      background: ${({ theme }) => theme.palette.white.main.hex()};
      box-sizing: border-box;
      border-radius: 10px;
      height: 40px;
      
      fieldset {
        border: solid 1px ${({ theme }) => theme.palette.grey._425.hex()};
      }

      &:hover fieldset, &.Mui-focused fieldset {
        border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()};
      }
    }

    .MuiInputBase-root {
      color: ${({ theme }) => theme.palette.grey._720.hex()};
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    input {
      width: 100%;
      ::placeholder {
        color: ${({ theme }) => theme.palette.grey._720.hex()};
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        opacity: 1;
      }
    }

    .tag {
      background-color: ${({ theme }) => theme.palette.blue._400.hex()};
      display: flex;
      box-sizing: border-box;
      border-radius: 4px;
      margin-right: 20px;

      span {
        margin: 4px 18px;
        color: ${({ theme }) => theme.palette.white.main.hex()};
        font-family: 'Quicksand', sans-serif;
        font-weight: 700;
        font-size: 14px;
      }
    }

    .MuiButtonBase-root {
      padding: 0px;
      margin-top: 4px;
    }
    
    ${({ error }) => error && css`
      fieldset {
        border: solid 1px ${({ theme }) => theme.palette.red._650.hex()};
      }
      &:hover fieldset {
        border: solid 1px ${({ theme }) => theme.palette.red._650.hex()};
      }
    `};
  }
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Label = styled.h1`
  color: ${({ theme }) => theme.palette.blue._950.hex()};
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding-bottom: 8px;
`;
