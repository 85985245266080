import styled, { css, keyframes } from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  } to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ContainerLabel = styled.div`
  p {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 8px;
    line-height: 17.5px;
    font-family: "Quicksand", sans-serif;
    color: ${({ theme }) => theme.palette.blue._950.hex()};
  }
`;

export const CountrySelectorStyled = styled(PhoneInput)<{
  error?: boolean,
}>`
  &&.react-tel-input {
    width: 100%;

    input:disabled  {
      color: ${({ theme }) => theme.palette.grey._450.hex()} !important;
    }
    .flag-dropdown {
      left: 6px;
      border-left: none !important;
      background-color: #fff !important;
    }

    ${({ error }) => error ? css`
      :hover {
        border: none !important;
        border-radius: 10px;
      }
      input {
        border: solid 1px ${({ theme }) => theme.palette.red._650.hex()} !important;
      }
      .flag-dropdown {
        border-color: ${({ theme }) => theme.palette.red._650.hex()} !important;
      }
    ` : css`
        :not(:disabled):hover {
          input {
            border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()} !important;
          }
          .flag-dropdown {
            border-color: ${({ theme }) => theme.palette.blue._400.hex()} !important;
          }
        }
    `};
  }

  &&.react-tel-input .form-control {
    width: 100%;
    box-shadow: none;
    transition: 0.3s;
  }

  &&.react-tel-input .country-list {
    border-radius: 10px;
    border: 1.5px solid #e0e0e0;
    padding: 10px;
    transition: 0.3s;
    opacity: 0;
    box-shadow: none;

    user-select: none !important;

    animation: ${fadeIn} 0.3s ease forwards;

    &::-webkit-scrollbar {
      width: 5px;
      padding: 10px;
      overflow: hidden;
    }

    &::-webkit-scrollbar-track {
      position: absolute;
      overflow: hidden;
      padding: 10px;
      background: #f1f1f1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      overflow: hidden;
      height: 50px;
      border-radius: 2px;
    }
  }
`;
