import React from 'react';
import { FormControl, RadioGroup } from '@mui/material';

import {
  FormLabelStylized,
  FormControlLabelStylized,
  RadioStylized,
  Container,
  LabelContainer,
  Label,
  StarSymbol,
} from './styles';
import { UseFormik } from '../../utils/Formik/types';
import HelperText from '../HelperText/HelperText';
import { ErrorIcon } from '../TextField/styles';
import { Type } from '../HelperText/types';

export type Value = string | boolean | null;

export interface RadioButtonOptions {
  value: Value;
  label: string;
}

export interface IRadioButtonProps {
  formLabel: string,
  id: string,
  labelPlacement: 'end' | 'start' | 'top' | 'bottom',
  options: RadioButtonOptions[],
  formik?: UseFormik<any>,
  helperText?: string,
  onClick?: Function,
  dataCy?: string,
  required?: boolean,
  disabled?: boolean,
  variant?: '1' | '2',
}

const RadioButton: React.FC<IRadioButtonProps> = ({
  formLabel,
  labelPlacement,
  options,
  id,
  formik,
  helperText,
  onClick,
  dataCy = '',
  required = false,
  disabled = false,
  variant = '1',
}) => {
  const handleChange = (selected: Value) => {
    formik?.setFieldValue(id, selected);
    formik?.setFieldTouched(
      id, true, false,
    );
    if (onClick) {
      onClick();
    }
  };

  const isHelperTextError = formik?.touched[id] && formik?.errors[id] !== '' && formik?.errors[id];

  const helperTextValue = () => {
    if (isHelperTextError) {
      return (
        <>
          <ErrorIcon />
          {formik?.errors[id]}
        </>
      );
    }

    return helperText;
  };

  const helperTextType = isHelperTextError ? Type.error : Type.primary;

  const LabelComponent = () => {
    if (required) {
      return (
        <LabelContainer>
          <StarSymbol>*</StarSymbol>
          <Label>{formLabel}</Label>
        </LabelContainer>
      )
    }
    return <FormLabelStylized focused={false}>{formLabel}</FormLabelStylized>
  };

  return (
    <Container>
      <FormControl data-cy={dataCy} component="fieldset">
        {variant === '1' && <LabelComponent />}
        <RadioGroup
          row
          aria-label={id}
          name={id}
          id={id}
        >
          {options.map(({ value, label }, index) => (
            <FormControlLabelStylized
              key={value ? value.toString() : index}
              value={value}
              disabled={disabled}
              control={(
                <RadioStylized
                  data-cy={`${dataCy}-${index + 1}`}
                  checked={formik?.values[id]?.toString() === value?.toString()}
                  onClick={() => handleChange(value)}
                />
              )}
              label={label}
              labelPlacement={labelPlacement}
            />
          ))}
        </RadioGroup>
        {variant === '2' && <LabelComponent />}
      </FormControl>
      {!!helperTextValue() && (
        <HelperText type={helperTextType}>{helperTextValue()}</HelperText>
      )}
    </Container>
  );
};

export default RadioButton;
