import { AccountOwnerOnboardingProps } from "../../services/Onboarding/types";

export const ownerExists = (owner: AccountOwnerOnboardingProps) => {
  if (!owner) return false;
  return Object.keys(owner).length !== 0;
};

export const ownerAlreadyExists = (personId: string, listOwner: Array<AccountOwnerOnboardingProps>) => {
  if (['', 'null', 'undefined'].includes(`${personId}`.trim().toLowerCase())) return false;
  const ownerFiltered = listOwner.filter((item) => `${item?.pipedrive_person_id}` === `${personId}`);
  return ownerFiltered.length !== 0 ? ownerFiltered?.[0] : null;
};

export const propertyCodeAlreadyExists = (propertyCode: string, listOwner: Array<AccountOwnerOnboardingProps>) => {
  const code = `${propertyCode}`.trim().toUpperCase();
  if (['', 'null', 'undefined'].includes(code)) return false;
  const propertyFiltered = listOwner.filter((item) => item?.property_codes?.includes(code));
  return propertyFiltered.length !== 0 ? propertyFiltered?.[0] : null;
};
