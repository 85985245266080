import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { InputAdornment } from '@mui/material';

import { ownerAlreadyExists } from '../../utils';
import { getAccountUserDetailsByUserId } from '../../../../services/User/request';
import { AccountOwnerOnboardingProps } from '../../../../services/Onboarding/types';
import { getInfosPipedrive } from '../../../../services/Onboarding/request';
import { ErrorMessage } from '../../../../utils/Messages';

import { useOnboardingHandover } from '../../../../hooks/useOnboarding/useOnboardingHandover';
import { useToast } from '../../../../context/ToastContext';

import TextField from '../../../TextField';
import FormButton from '../../../FormButton';
import Spinner from '../../Spinner';

import {
  Section,
  Title,
  Form,
  ButtonSyncPipedrive,
  TextFieldSyncPipedrive,
  Divider,
} from './styles';

export default function AddOwner() {
  const toast = useToast();

  const {
    listOwner,
    loadingOwner,
    setPageVisible,
    setInfosPipeDrive,
    dealID,
    setDealID,
    setResetFormik,
    owner,
    setOwner,
  } = useOnboardingHandover();

  const handleGetUserDetails = async (userId: number) => {
    const ownerDetails = await getAccountUserDetailsByUserId(userId);
    setOwner({
      ...owner,
      ...ownerDetails,
      user_id: ownerDetails.id,
      owner_id: ownerDetails.owner.id,
      name: `${ownerDetails.first_name} ${ownerDetails.last_name}`,
    });
  };

  const validation = Yup.object().shape({
    dealID: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: { dealID },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        const result = await getInfosPipedrive(values.dealID);
        setInfosPipeDrive(result);
        setDealID(values.dealID);

        const ownerExists = ownerAlreadyExists(`${result?.owner_infos?.owner_person_id}`, listOwner);
        
        if (ownerExists) {
          await handleGetUserDetails(ownerExists.user_id);
          setPageVisible('Page_Forms');
        } else {
          setOwner({} as AccountOwnerOnboardingProps)
          setPageVisible('Page_Forms');
          toast.success('Dados sincronizados com sucesso!');
        }
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          if (e?.response) {
            if (e?.response?.status === 404) {
              toast.error('DEAL ID não encontrado');
            } else {
              toast.error(e.response.data || ErrorMessage.default());
            }
          }
        } else {
          toast.error(ErrorMessage.default());
        }
      }
    },
  });

  const handleAddManually = () => {
    setOwner({} as AccountOwnerOnboardingProps);
    setResetFormik(true);
    setPageVisible('Page_Forms');
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <Section>
        <Title>Adicionar Proprietário</Title>
        <Form onSubmit={formik.handleSubmit} isShowingErrorMessage={!formik.errors.dealID} className="first-form">
          <TextFieldSyncPipedrive>
            <TextField
              formik={formik}
              id="dealID"
              label="Sincronizar Código Pipedrive"
              placeholder="DEAL ID:"
              labelClassName="field-deal"
              value={formik.values.dealID}
              disabled={formik.isSubmitting || loadingOwner}
              disableHelperText
              endAdornment={(
                <InputAdornment position="end">
                  <Spinner loading={formik.isSubmitting} />
                </InputAdornment>
              )}
            />
          </TextFieldSyncPipedrive>
          <ButtonSyncPipedrive>
            <FormButton
              dataCy="btn-pull-pipedrive"
              type="button"
              customClassName="button-deal"
              disable={formik.isSubmitting || loadingOwner}
              onClick={() => handleSubmit()}
            >
              {formik.isSubmitting ? 'Sincronizando...' : 'Sincronizar'}
            </FormButton>
          </ButtonSyncPipedrive>
          <Divider />
          <ButtonSyncPipedrive>
            <FormButton
              dataCy="btn-pull-pipedrive"
              type="button"
              variant="outlined"
              customColor="blue"
              disable={formik.isSubmitting}
              onClick={() => handleAddManually()}
            >
              Adicionar manualmente
            </FormButton>
          </ButtonSyncPipedrive>
        </Form>
      </Section>
    </>
  );
}
