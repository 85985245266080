/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import { FC } from 'react';

import TooltipInfo from '../TooltipInfo/TooltipInfo';

import {
  Container,
  TitleContainer,
} from './styles';

interface Props {
  title: string;
  valueJan?: string;
  valueFeb?: string;
  valueMar?: string;
  valueApr?: string;
  valueMay?: string;
  valueJun?: string;
  valueJul?: string;
  valueAug?: string;
  valueSep?: string;
  valueOut?: string;
  valueNov?: string;
  valueDec?: string;
  transfer?: boolean;
  totalValue?: string;
  isVisible: boolean;
  hideTotal?: boolean;
  isSelectedOptionTODOS?: boolean;
}

const LineGrid: FC<Props> = ({
  title,
  valueJan = '-',
  valueFeb = '-',
  valueMar = '-',
  valueApr = '-',
  valueMay = '-',
  valueJun = '-',
  valueJul = '-',
  valueAug = '-',
  valueSep = '-',
  valueOut = '-',
  valueNov = '-',
  valueDec = '-',
  totalValue = '-',
  isVisible = true,
  hideTotal = false,
  isSelectedOptionTODOS = true,
}) => {
  const getDescription: Record<string, string | JSX.Element> = {
    'A receber no mês atual': isSelectedOptionTODOS ? <p>Valor total das diárias <strong>de todos os seus imóveis</strong>, com estadia no mês que são pagas no mês, somado aos ajustes financeiros positivos. (Airbnb ou Website Seazone).</p> : 'Valor total das diárias com estadia no mês que não possuem atraso no recebimento, somado aos ajustes financeiros positivos. São as diárias vendidas via Airbnb ou Website Seazone.',
    'A receber no mês seguinte': isSelectedOptionTODOS ? <p>Valor total das diárias <strong>de todos os seus imóveis</strong>, com estadia no mês, que serão pagas no mês seguinte (Booking, Expedia ou Decolar)</p> : 'Valor total das diárias com estadia no mês mas que possuem atraso de um mês para o recebimento. São as diárias que vendidas via Booking, Expedia ou Decolar.',
    'Diárias do mês atual': isSelectedOptionTODOS ? <p>Valor total das diárias <strong>de todos os seus imóveis</strong>, com estadia no mês que são pagas no mês, somado aos ajustes financeiros positivos. (Airbnb ou Website Seazone).</p> : 'Valor total das diárias com recebimento para o mês atual, independente da data da estadia.',
    'Diárias do mês anterior': isSelectedOptionTODOS ? <p>Valor total das diárias <strong>de todos os seus imóveis</strong>, com estadia no mês anterior e pagas no mês atual (Booking, Expedia, Decolar).</p> : 'Valor total das diárias com estadia no mês anterior com atraso de recebimento para o mês atual. Este valor representa o valor total das diárias que entraram via Booking, Expedia ou Decolar no mês anterior.',
    Despesas: isSelectedOptionTODOS ? <p>Valor total das <strong>despesas, ajustes financeiros negativos ou taxa de adesão</strong> do mês de referência, <strong>de todos os seus imóveis.</strong> Para detalhes acesse seu extrato financeiro.</p> : <p>Valor total das <strong>despesas, ajustes financeiros negativos ou taxa de adesão</strong> do mês de referência. Para detalhes acesse seu extrato financeiro.</p>,
    Comissão: isSelectedOptionTODOS ? <p>Valor referente ao pagamento do total da Comissão Seazone pela prestação de serviços no mês de referência, <strong>de todos os seus imóveis</strong>.</p> : <p>Valor referente ao pagamento do total da Comissão Seazone pela prestação de serviços no mês de referência.</p>,
    'Repasse (TED)': isSelectedOptionTODOS ? <p><strong>Somatório dos repasses realizados, de todos os seus imóveis</strong>. Este valor foi transferido no mês posterior ao mês de competência. Exemplo: Repasse de Janeiro foi transferido no mês de Fevereiro.</p> : <p><strong>Valor do repasse realizado.</strong> Este valor foi transferido no mês posterior ao mês de competência. Exemplo: Repasse de Janeiro foi transferido no mês de Fevereiro.</p>,
    Saldo: isSelectedOptionTODOS ? <p>Somatório dos Saldos <strong>de todos os seus imóveis</strong>, que podem incluir créditos (diárias a pagar no mês) ou débitos (despesas a deduzir), pendentes no mês atual e a serem contabilizados no próximo mês. Para esclarecimentos adicionais, consulte o valor do Saldo individual de cada imóvel.</p> : <p>Valor total de créditos (diárias a pagar no mês) ou débitos (despesas a descontar) que <strong>ficaram pendentes</strong> neste mês e serão contabilizados no mês posterior.</p>,
  };

  return (
    <Container
      isVisible={isVisible}
    >
      <TitleContainer>
        <span>{title}</span>
        <TooltipInfo
          title={title === 'Saldo final' ? `${title} do período` : title}
          subtitle={getDescription[title]}
          marginRight="1.2rem"
        />
      </TitleContainer>

      <p className="spacing" />
      <p>{valueJan}</p>
      <p>{valueFeb}</p>
      <p>{valueMar}</p>
      <p>{valueApr}</p>
      <p>{valueMay}</p>
      <p>{valueJun}</p>
      <p>{valueJul}</p>
      <p>{valueAug}</p>
      <p>{valueSep}</p>
      <p>{valueOut}</p>
      <p>{valueNov}</p>
      <p>{valueDec}</p>
      {!hideTotal && (
      <p style={{ fontWeight: 700 }}>{totalValue}</p>)}
    </Container>
  );
};

export default LineGrid;
