import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 21px 0 10px 16px;
  border-bottom: 1px solid ${({theme}) => theme.palette.grey._425.hex()};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
`;

export const Content = styled.div`
  &.activeBorder {
    border-right: 1px solid ${({theme}) => theme.palette.blue._90.hex()};
    padding-right: 25px;
  }
`;

export const ContentLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ContentRight = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

export const Title = styled.h2`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  text-align: left;
  color: ${({theme}) => theme.palette.blue._250.hex()};
`;

export const Text = styled.p`
  font-family: Quicksand;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
  text-align: left;
  max-width: 434px;
  padding-top: 8px;
  
  &.ellipsis {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.description {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const TextBold = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  color: ${({theme}) => theme.palette.blue._250.hex()};
`;

export const BackButtonStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;
