import styled from 'styled-components';

export const ContainerSlider = styled.div`
  width: 100%;
  height: auto;

 .slick-track {
    display: flex !important;
  }
  .slick-slide {
    margin: 0px !important;
  }
  .slick-prev:before,
  .slick-next:before {
    color: ${({ theme }) => theme.palette.blue._970.hex()} !important;
  }
  .slick-dots li button {
    background-color: ${({ theme }) => theme.palette.blue._30.hex()} !important;    
    border-radius: 10px !important;
  }
  .slick-dots li.slick-active button:before {
    color: ${({ theme }) => theme.palette.blue._400.hex()} !important;    
  }
`;

export const Container = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  background: ${({ background }) => background};
  border-top: 1px solid #000;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
  }
  
  img {
    position: relative;
    width: 100vw !important;
    height: 300px !important;
    object-fit: contain !important;   
  }

  @media (max-width: 900px) {
    height: 150px !important;
    
    img {
      width: 100% !important;
      height: 150px !important;
    }
  }
`;
