import { useEffect, useMemo } from 'react';

import { ConfirmationAnimation } from '../../../assets/animations';
import { ownerExists } from '../utils';
import { useOnboardingHandover } from '../../../hooks/useOnboarding/useOnboardingHandover';
import { Container, Content, Title, Description } from './styles';

const PageFinal = () => {
  const { owner,setPageVisible } = useOnboardingHandover();
  const ownerAlreadyExists = useMemo(() => ownerExists(owner), [owner]);

  useEffect(() => {
    setTimeout(() => {
      setPageVisible('Page_Initial');
    }, 3000);
  }, []);

  return (
    <Container>
      <ConfirmationAnimation variant="2" />
      <Content>
        <Title>{!ownerAlreadyExists ? 'Proprietário Adicionado' : 'Imóvel Adicionado'}</Title>
        <Description>Dados salvos com sucesso</Description>
      </Content>
    </Container>
  );
};

export default PageFinal;
