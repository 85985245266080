import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div<{ loading: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-bottom: 2px;
  background: 
    radial-gradient(circle, white 60%, transparent 60%),
    conic-gradient(from 0deg at 50% 50%, #2B52A2 0deg, rgba(196, 196, 196, 0) 360deg);

  ${({ loading }) => (loading
    ? css`
      animation: ${rotate} 1s linear infinite;
  ` : css`
      display: none;
    `)}
`;
