import styled, { css } from 'styled-components';
import { FormControl, Select } from '@mui/material';

export const Container = styled.div`
  .MuiNativeSelect-select {
    width: 100%;
    background: transparent !important;
    border: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    margin-top: 5px;

    legend {
      display: none !important;
    }
  }

  @media (max-width: 1180px) {
    width: 100%;
  }
`;

export const ImageCalendar = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 990;
  margin-top: 10px;
  margin-left: 10px;
`;

export const ContainerLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

export const ContentLabel = styled.div`
  display: flex;
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.familys._6};
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;

export const StyledFormControl = styled(FormControl)<{
  label?: string;
}>`
  && {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    & label {
      color: ${({ theme }) => theme.palette.blue._950.hex()};
      font-family: ${({ theme }) => theme.fonts.familys._6};
    }
  }
`;

export const StyledSelect = styled(Select)<{
  showIcon: boolean;
  isEstimate?: boolean;
  error: boolean;
}>`
  && {
    max-height: 40px;
    background: ${({ theme }) => theme.palette.white.main.hex()};
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: none;
    outline: none;
    width: 100%;
    z-index: 10;
    padding-left: ${({ showIcon }) => (showIcon ? '25px' : 'auto')};

    fieldset {
      border: solid 1px ${({ theme }) => theme.palette.grey._425.hex()};
    }

    & option {
      color: ${({ theme }) => theme.palette.blue._250.hex()};
      font-family: Inter;
      font-weight: 700;
      font-size: ${({ isEstimate }) => (isEstimate ? '15px' : '14px')};
    }
    & svg {
      height: ${({ isEstimate }) => isEstimate && '28px'};
      width: ${({ isEstimate }) => isEstimate && '28px'};
      margin-top: ${({ isEstimate }) => isEstimate && '-3px'};
    }
    & #placeholder {
      color: ${({ theme }) => theme.palette.grey._420.hex()};
    }

    & .MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
      color: ${({ theme }) => theme.palette.blue._250.hex()};
      font-family: ${({ theme }) => theme.fonts.familys._6};
      font-weight: 400;
      font-size: ${({ isEstimate }) => (isEstimate ? '15px' : '14px')};
    }

    select {
      color: ${({ theme }) => theme.palette.blue._250.hex()};
    }

    &:hover fieldset {
      border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()};
    }

    ${({ error }) => error ? css`
      fieldset, input {
        border: solid 1px ${({ theme }) => theme.palette.red._650.hex()} !important;
      }
    ` : css`
        :not(:disabled):hover {
          fieldset, input {
            border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()} !important;
          }
        }
    `};

    & .MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
      background: transparent;
    }
  }
`;
