import { IBannerSlider } from "./types";

import BANNER_1 from '../../../assets/banners/seazone_banner-sapron_spot-sto-antonio_desktop.jpg';
import BANNER_2 from '../../../assets/banners/easy-cover-desktop.jpg';

export const bannersDeskList: Array<IBannerSlider> = [
  {
    id: 1,
    image: BANNER_1,
    alt: "Imagem do banner",
    link: 'https://institucional.seazone.com.br/investimentos/szi-santo-antonio-spot/?utm_campaign=18991230_todas_si_mofu&utm_medium=banner&utm_source=sapron',
    background: '#001740',
    infos_analytics: {
      name: 'Banner_Santo_Antonio_Spot_Click'
    }
  },
  {
    id: 2,
    image: BANNER_2,
    alt: "Imagem do banner",
    link: 'https://seazone.nowseguros.seg.br/',
    background: '#f7f7f7',
    infos_analytics: {
      name: 'Banner_Easy_Cover_Click'
    }
  },
];
