import styled, { css } from 'styled-components';
import { FormControl, TextField, TextareaAutosize } from '@mui/material';
import { ReportProblemOutlined } from '@mui/icons-material';

export const StyledFormControl = styled(FormControl)`
  margin-bottom: ${({ theme }) => theme.spacing.form};
  width: 100%;

  && label {
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17.5px;
    padding-bottom: 8px;
  }

  button {
    position: absolute;
    z-index: 12;
    margin-left: -100px;
    right: 0;
    margin-right: 35px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    color: ${({ theme }) => theme.palette.blue._850.hex()};
    font-size: 12px;
    font-weight: 700;
    border: none;
    background: transparent;
    cursor: pointer;

    &:hover {
      transform: translateX(10px) scale(1.2);
    }

    @media (max-width: 580px) {
      margin-right: 25px;
    }
  }
`;

export const ContainerLabel = styled.div`
 #label-description {
    margin: 0.3rem 0 0.6rem 0;
    font-size: 0.7rem;
    font-weight: 500;
    font-family: Inter;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
 }
`;

export const ContentLabel = styled.div`
  display: flex;
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTextField = styled(TextField)<{
  activeButton: boolean;
  disabled: boolean;
}>`
  && {
    .MuiOutlinedInput-root {
        height: 40px;
        background: ${({ theme }) => theme.palette.white.main.hex()};
        box-sizing: border-box;
        border-radius: 10px;
        padding-right: ${({ activeButton }) => (activeButton ? '75px' : '0')};
        
        fieldset {
          box-sizing: border-box !important;
          border: solid 1px ${({ theme }) => theme.palette.white._430.hex()};
          margin-top: 2.8px;
        }

        &:hover fieldset {
          border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()};
        }
        &.Mui-focused fieldset {
          border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()};
        }

        ${({ disabled }) => disabled && css`
          fieldset {
            border: solid 1px ${({ theme }) => theme.palette.grey._425.hex()};
          }
          &:hover fieldset {
            border: solid 1px ${({ theme }) => theme.palette.grey._425.hex()};
          }
          &.Mui-focused fieldset {
            border: solid 1px ${({ theme }) => theme.palette.grey._425.hex()};
          }
        `};

        ${({ error }) => error && css`
          fieldset {
            border: solid 1px ${({ theme }) => theme.palette.red._650.hex()};
          }
          &:hover fieldset {
            border: solid 1px ${({ theme }) => theme.palette.red._650.hex()};
          }
          &.Mui-focused fieldset {
            border: solid 1px ${({ theme }) => theme.palette.red._650.hex()};
          }
        `};
    }

    .MuiInputBase-root {
      color: ${({ theme }) => theme.palette.blue._950.hex()};
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 5px;
    }

    .MuiInputBase-input {
      box-sizing: border-box !important;
      ::placeholder {
        color: ${({ theme }) => theme.palette.grey._720.hex()};
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        opacity: 1;
      }
      :focus {
        ::placeholder {
          color: ${({ theme }) => theme.palette.grey._550.hex()};
        }
      }
    }

    .MuiInputAdornment-root {
      color: ${({ theme }) => theme.palette.grey._720.hex()};
    }

    .MuiOutlinedInput-notchedOutline {
      legend {
        display: none !important;
      }
    }
  }
`;

export const StyledTextArea = styled(TextareaAutosize)<{
  error?: boolean;
}>`
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    border: none;
    display: block;
    background-color: transparent;
    overflow: hidden;
  }
  && {
    background: ${({ theme }) => theme.palette.white.main.hex()};
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
    padding: 10px 13px 10px 13px;
    resize: none;
    min-height: 120px !important;
    max-height: ${({ minRows }) => (minRows ? 'auto' : '120px')};
    overflow-y: scroll !important;
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17.5px;
    letter-spacing: 0.00938em;

    ::placeholder {
      color: ${({ theme }) => theme.palette.grey._720.hex()};
    }

    :focus {
      ::placeholder {
        color: ${({ theme }) => theme.palette.grey._550.hex()};
      }
    }

    :focus, :active, :hover {
      outline: 0;
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.palette.blue._400.hex()};
    }

    ${({ error }) => error && css`
      border: solid 1px ${({ theme }) => theme.palette.red._650.hex()};
    `};
  }
`;

export const ErrorIcon = styled(ReportProblemOutlined)`
&& {
  margin-right: 3px;
  height: 16px;
}
`;
