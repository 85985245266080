import { useMemo } from 'react';
import { UseFormik } from '../../../../../utils/Formik/types';

import { ownerExists } from '../../../utils';

import { useOnboardingHandover } from '../../../../../hooks/useOnboarding/useOnboardingHandover'

import OnboardingSwitch from '../../Fields/OnboardingSwitch';

import {
  Wrapper,
  Content,
  ContentLeft,
  ContentRight,
  TitleContainer,
  Title,
  Text,
  TextBold,
  BackButtonStyled,
} from './styles';

interface ICardNewOwner {
  formik: UseFormik<any>,
}

const CardNewOwner = ({ formik }: ICardNewOwner) => {
  const { resetVisibility, setPageVisible, owner, listPartner } = useOnboardingHandover();

  const ownerAlreadyExists = useMemo(() => ownerExists(owner), [owner]);

  const handleBackToPageInitial = () => {
    resetVisibility();
    setPageVisible('Page_Initial');
  };

  const BackButton = () => (
    <BackButtonStyled onClick={() => handleBackToPageInitial()}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9999 5.00019H3.4099L6.7099 1.71019C6.8982 1.52188 7.00399 1.26649 7.00399 1.00019C7.00399 0.733884 6.8982 0.478489 6.7099 0.290185C6.52159 0.101882 6.2662 -0.00390625 5.9999 -0.00390625C5.73359 -0.00390625 5.4782 0.101882 5.2899 0.290185L0.289896 5.29019C0.198856 5.38529 0.127491 5.49743 0.0798963 5.62019C-0.0201217 5.86365 -0.0201217 6.13672 0.0798963 6.38019C0.127491 6.50294 0.198856 6.61508 0.289896 6.71019L5.2899 11.7102C5.38286 11.8039 5.49346 11.8783 5.61532 11.9291C5.73718 11.9798 5.86788 12.006 5.9999 12.006C6.13191 12.006 6.26261 11.9798 6.38447 11.9291C6.50633 11.8783 6.61693 11.8039 6.7099 11.7102C6.80363 11.6172 6.87802 11.5066 6.92879 11.3848C6.97956 11.2629 7.00569 11.1322 7.00569 11.0002C7.00569 10.8682 6.97956 10.7375 6.92879 10.6156C6.87802 10.4937 6.80363 10.3831 6.7099 10.2902L3.4099 7.00019H10.9999C11.2651 7.00019 11.5195 6.89483 11.707 6.70729C11.8945 6.51976 11.9999 6.2654 11.9999 6.00019C11.9999 5.73497 11.8945 5.48062 11.707 5.29308C11.5195 5.10554 11.2651 5.00019 10.9999 5.00019Z" fill="black" />
      </svg>
    </BackButtonStyled>
  );

  const partner = useMemo(() => listPartner.filter((item) => `${item.id}` === `${formik.values.indicationName}`), [formik.values.indicationName])?.[0] || '';

  return (
    <Wrapper>
      <ContentLeft>
        <Content className="activeBorder">
          <TitleContainer>
            <BackButton />
            <Title>{ownerAlreadyExists ? 'Novo Imóvel' : 'Novo Proprietário'}</Title>
          </TitleContainer>
          <Text className="description">
            {`Confira os campos faltantes para completar cadastro do ${ownerAlreadyExists ? 'imóvel' : 'proprietário'}.`}
          </Text>
        </Content>
      </ContentLeft>

      <ContentRight>
        <Content>
          <TextBold>Proprietário</TextBold>
          <Text className="ellipsis">{`${formik.values.name} ${formik.values.lastname}`}</Text>
        </Content>
        <Content>
          <TextBold>Person ID</TextBold>
          <Text>{formik.values.personID}</Text>
        </Content>
        <Content>
          <TextBold>Cliente estrangeiro</TextBold>
          {ownerAlreadyExists ? (
            <Text>{formik.values.isForeignUser ? 'Sim' : 'Não'}</Text>
          ) : (
            <OnboardingSwitch
              id="isForeignUser"
              formik={formik}
              value={formik.values.isForeignUser}
            />
          )}
        </Content>
        <Content>
          <TextBold>Foi indicado por algum parceiro</TextBold>
          <Text>{!formik.values.isPartnerIndication ? '' : `${partner?.user?.first_name} ${partner?.user?.last_name}`}</Text>
        </Content>
      </ContentRight>
    </Wrapper>
  );
};

export default CardNewOwner;
