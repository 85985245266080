import Lottie from 'lottie-react';

import confirmAnimation from '../source/confirmationAnimation.json';
import confirmAnimation2 from '../source/confirmationBlueAnimation.json';

interface IConfirmationAnimation {
  variant?: '1' | '2',
};

const ConfirmationAnimation = ({ variant = '1'}: IConfirmationAnimation) => (
  <Lottie animationData={variant === '1' ? confirmAnimation : confirmAnimation2} />
);

ConfirmationAnimation.defaultProps = {
  variant: '1',
};

export { ConfirmationAnimation };
