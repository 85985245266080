import styled, { keyframes, css } from 'styled-components';

export const animationTime = 2;

export const shimmerAnimation = keyframes`
  0% {
      background: linear-gradient(-90deg,  #d3d3d3 0%, #e0e0e0 50%,  #d3d3d3 100%);
      background-size: 400% 400%;
      background-position: 100% 0%;
    }
  50% {
    background-position: 0% 0%;
  }
  100% {
    background: linear-gradient(-90deg,  #d3d3d3 0%, #e0e0e0 50%,  #d3d3d3 100%);
      background-size: 400% 400%;
      background-position: 0% 0%;
  }
`;

const GRID_WIDTH = '34% 17% 44% 5%';

export const ContainerArrows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  img {
    padding: 0.05rem 0 0.05rem 0.5rem;
    cursor: pointer;
  }
`;

export const Image = styled.img``;

export const InfoOutlinedIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 8px 0 4px;

  svg {
    width: 15px;
    height: 15px;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 28px;
  
  @media(max-width: 900px) {
    margin-top: 8px;
    height: fit-content;
  }
`;

export const GridContent = styled.div`
  width: 100%;
  height: 100%;
  min-height: 250px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  
  @media(max-width: 900px) {
    min-height: 150px;
  }
`;

export const GridHeaderContainer = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  display: grid;
  grid-template-columns: ${GRID_WIDTH};
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
`;

export const GridHeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 64px 16px 8px;

  span {
    width: fit-content;
  }
  
  div {
    width: fit-content;
  }
`;

export const GridHeaderTitle = styled.span<{ hide: string }>`
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  text-align: start;
  visibility: ${({ hide }) => [''].includes(hide) ? 'hidden' : 'visible'};
`;

export const GridBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const GridBodyContent = styled.div`
  padding: 12px 32px 12px 8px;
  flex-shrink: 0;
`;

export const GridBodyRow = styled.div<{ loading: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${GRID_WIDTH};  
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};

  ${({loading}) => loading && css`
    animation: ${animationTime}s ${shimmerAnimation} ease-in-out infinite;
    border: 5px solid #fff;
    border-radius: 10px;
    opacity: 0.5;
    transition: 0.5s;

    .title-body {
      visibility: hidden;
    }
  `};

  :hover {
    background: #EAF4FF;
    transition: 1.4s;
  }
`;

export const GridBodyTitle = styled.span`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  font-family: 'Plus Jakarta Sans', 'Inter';
  color: #161616;
  hyphens: auto;
  -webkit-hyphens: auto;
  max-width: 400px;
  text-align: start;

  .properties-counter {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media(max-width: 900px) {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: auto;
`;

export const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};

  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.grey._320.hex()};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
    border-left: 1px solid ${({ theme }) => theme.palette.grey._320.hex()};
    border-right: 1px solid ${({ theme }) => theme.palette.grey._320.hex()};
  }
`;

export const CardContentLeft = styled.div`
  width: 100%;
  height: auto;
  padding: 16px;
`;

export const CardContentRight = styled.div`
  width: 100%;
  height: auto;
  padding: 16px;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px 0;
`;

export const FormButtonContainer = styled.div`
  button {
    margin-top: 8px;
    width: 78%;
    gap: 10px;
  }
`;

export const TooltipContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  gap: 4px;
  border-radius: 34px;
  background: #CCE4FF;
  margin-left: 10px;
  width: 49.33px;
  height: auto;
  max-height: fit-content;
  
  span {
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: 22px;
  }
`;
