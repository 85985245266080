import AddOwner from "./AddOwner";
import SearchOwners from "./SearchOwners";

import { Container, Content } from './styles';

const PageInitial = () => (
  <Container>
    <Content>
      <AddOwner />
    </Content>
    <Content>
      <SearchOwners />
    </Content>
  </Container>
);

export default PageInitial;
