/* eslint-disable no-await-in-loop */
import React, { useCallback } from 'react';

import { Button, Fab } from '@mui/material';
import { ImportExport as ImportExportIcon } from '@mui/icons-material';

import { useLoader } from '../../../../../context/LoaderContext';
import { useToast } from '../../../../../context/ToastContext';
import { getFilesByFileUid } from '../../../../../services/Utils/request';
import { UseFormik } from '../../../../../utils/Formik/types';
import { sleep } from '../../../../../utils/Sleep';

import { Container } from './styles';
import { useHostTedList } from '../../../../../hooks/HostTedListHook/useHostTedList';
import { addZeroLeft } from '../../../../../utils/Formatter';
import { genFileIdToDownloadHostTedListCSV } from '../../../../../services/HostTedList/request';

interface IExportListButton {
  children: string;
  formik: UseFormik<any>;
  fab?: boolean;
}

function ExportListButton({ children, formik, fab }: IExportListButton) {
  const toast = useToast();

  const { setLoad } = useLoader();
  const { loading } = useHostTedList();

  const polling = useCallback(async (fileID: string) => {
    let counter = 0;
    const tries = 5;
    const conditionalTries = tries - 1;

    while (counter !== tries) {
      await sleep(2000);
      const { url } = await getFilesByFileUid(fileID);

      if (url) {
        window.open(url, '_blank');
        toast.success('CSV baixado com sucesso');
        setLoad(false);
        break;
      }

      if (counter === conditionalTries) {
        toast.error('Erro ao gerar o arquivo, tente novamente mais tarde');
        setLoad(false);
        break;
      }

      counter += 1;
    }
  }, []);

  const handleClick = async () => {
    setLoad(true);
    const monthWithZeroLeft = addZeroLeft(`${formik.values.period.getMonth() + 1}`);
    const formattedPeriod = `${formik.values.period.getFullYear()}-${monthWithZeroLeft}`;

    const params = {
      period: formattedPeriod,
      termToFilter: formik.values.search,
    };

    const { file_uid: fileID, status } = await genFileIdToDownloadHostTedListCSV(params);

    switch (status) {
      case 'Processing':
        polling(fileID);
        break;
      case undefined:
        toast.error('Erro ao gerar o arquivo, tente novamente mais tarde');
        setLoad(false);
        break;
      default:
        toast.error('Nenhum dado foi encontrado');
        setLoad(false);
        break;
    }
  };

  if (fab) {
    return (
      <Fab
        size="small"
        color="primary"
        disabled={loading}
        variant="extended"
        onClick={handleClick}
        aria-label="export-csv"
      >
        <ImportExportIcon style={{ marginRight: '5px' }} />
        {children}
      </Fab>
    );
  }

  return (
    <Container>
      <Button
        disabled={loading}
        data-cy="btn-export-list-tedList"
        onClick={handleClick}
        type="button"
      >
        <h1>{children}</h1>
      </Button>
    </Container>
  );
}

ExportListButton.defaultProps = {
  fab: undefined,
};

export default ExportListButton;
