import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
`;

export const Title = styled.h2`
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 600;
  color: ${({theme}) => theme.palette.blue._400.hex()};
  padding-top: 12px;
`;

export const Description = styled.p`
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  color: ${({theme}) => theme.palette.blue._250.hex()};
  padding-top: 10px;
`;

